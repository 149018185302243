import * as R from 'ramda';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'react-recompose';
import { createStructuredSelector } from 'reselect';
import React, { useState, useCallback, useMemo } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// features
import PC from '../../../permission/role-permission';
import { makeSelectStyling } from '../../../styling/selectors';
import { makeSelectPinnedMenuItems } from '../../../profile/selectors';
// helpers/constants
import * as G from '../../../../helpers';
// icons
import * as I from '../../../../svgs';
// ui
import { Flex } from '../../../../ui';
// feature navbar-menu
import NavbarButton from './navbar-button';
import { makeSelectSidebar } from '../../selectors';
import SidebarMenuList from '../../configs/menus-map';
import { Divider, PinnedScrollbar, DeleteButton } from './ui';
import { removePinnedItemFromList, reorderPinnedItems } from '../../actions';
//////////////////////////////////////////////////

const getItemStyle = (isDragging: boolean, draggableStyle: Object) => ({
  userSelect: 'none',
  position: 'relative',
  background: G.ifElse(
    isDragging,
    G.getTheme('colors.light.navbarButtonHoverBackground'),
    'transparent',
  ),
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
  width: '100%',
  background: G.ifElse(
    isDraggingOver,
    G.getTheme('colors.light.navbarButtonHoverBackground'),
    'transparent',
  ),
});

const findItemInListDeep = (key: string, list: Array, nested: string) => {
  let found = null;

  const search = (list: Array) => (
    R.find((item: Object) => {
      if (R.equals(item.key, key)) {
        found = item;

        return true;
      } else if (G.isNotNilAndNotEmpty(G.getPropFromObject(nested, item))) {
        return search(G.getPropFromObject(nested, item));
      }

      return false;
    })(list)
  );

  search(list);

  return found;
};

const enhanceDraggable = compose(
  withHandlers({
    onDragEnd: ({ navItems, reorderPinnedItems }: Object) => ({ source, destination }: Object) => {
      const startIndex = G.getPropFromObject('index', source);
      const endIndex = G.getPropFromObject('index', destination);

      if (R.or(R.not(destination), R.equals(startIndex, endIndex))) return;

      const items = R.compose(
        R.insert(endIndex, R.path([startIndex], navItems)),
        R.remove(startIndex, 1),
      )(navItems);

      reorderPinnedItems(items);
    },
  }),
);

const getParentItemMenu = (menuList: Array, key: string) =>
  R.find((item: MenuItem) => {
    if (R.equals(R.path(['key'], item), key)) {
      return true;
    } else if (item.submenu) {
      return G.isNotNilAndNotEmpty(getParentItemMenu(item.submenu, key));
    }

    return false;
  })(menuList);

const DraggableList = enhanceDraggable(({
  navItems,
  onDragEnd,
  iconsColor,
  editPinned,
  currentMenuItem,
  handleClickNavItem,
  handleRemoveNavItem,
}: Object) => (
  <DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId='droppable'>
      {(provided: Object, snapshot: Object) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          style={getListStyle(snapshot.isDraggingOver)}
        >
          {navItems.map((item: Object, index: number) => {
            const { key, title, action } = item;

            return (
              <Draggable
                key={key}
                index={index}
                isDragDisabled={R.not(editPinned)}
                draggableId={`navbar-menu-pinned-dnd-item-${key}`}
              >
                {(provided: Object, snapshot: Object) => {
                  const parentItem = getParentItemMenu(SidebarMenuList, key);

                  return (
                    <div
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                    >
                      <NavbarButton
                        iconsColor={iconsColor}
                        text={G.getWindowLocale(key, title)}
                        onClick={() => handleClickNavItem(action)}
                        active={R.equals(currentMenuItem, action)}
                        cursor={G.ifElse(editPinned, 'grab', 'pointer')}
                        icon={R.pathOr(() => {}, [parentItem.iconName], I)}
                      />
                      {
                        editPinned &&
                        <DeleteButton onClick={(e: Object) => handleRemoveNavItem(e, item)}>
                          {I.trash(G.getTheme('colors.light.darkRed'))}
                        </DeleteButton>
                      }
                      {provided.placeholder}
                    </div>
                  );
                }}
              </Draggable>
            );
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </DragDropContext>
));

const getNavItems = (sidebar: Array, pinnedItems: Array) => R.reject(
  R.isNil,
  R.map(
    (item: string) => findItemInListDeep(item, sidebar, 'submenu'),
    pinnedItems),
);

const PinnedItems = ({
  styling,
  sidebar,
  currentMenuItem,
  pinnedItemsList,
  reorderPinnedItems,
  carrierPinnedItems,
  handleClickNavItem,
  removePinnedItemFromList,
}: Object) => {
  const [editPinned, setEditPinned] = useState(false);

  const iconsColor = R.pathOr(G.getTheme('colors.light.mainLight'), ['textColor'], styling);

  const isCarrier = G.isCurrentUserTypeCarrier();

  const pinnedItems = G.ifElse(isCarrier, carrierPinnedItems, pinnedItemsList);

  const navItems = useMemo(() => getNavItems(sidebar, pinnedItems), [sidebar, pinnedItems]);

  const adjustHeight = R.and(
    R.not(G.isCurrentBranchTypeCustomer()),
    G.hasAmousCurrentUserPermissions([PC.EXTERNAL_LOAD_BOARD_EXECUTE]),
  );

  const maxHeightDiff = G.ifElse(isCarrier, 190, G.ifElse(adjustHeight, 515, 405));

  const handleRemoveNavItem = useCallback((event: Event, item: Object) => {
    G.stopPropagation(event);
    removePinnedItemFromList(item.key);
  });

  const toggleEditPinned = useCallback(() => setEditPinned((prev: boolean) => R.not(prev)));

  return (
    <Flex
      flex={1}
      mb='10px'
      flexDirection='column'
    >
      <Flex
        flex={1}
        width='100%'
        flexDirection='column'
      >
        {R.not(isCarrier) && <Divider />}
        <PinnedScrollbar
          gap={8}
          width='100%'
          overflowY='auto'
          flexDirection='column'
          justifyContent='start'
          maxHeight={`calc(100vh - ${maxHeightDiff}px)`}
        >
          <DraggableList
            navItems={navItems}
            iconsColor={iconsColor}
            editPinned={editPinned}
            currentMenuItem={currentMenuItem}
            handleClickNavItem={handleClickNavItem}
            reorderPinnedItems={reorderPinnedItems}
            handleRemoveNavItem={handleRemoveNavItem}
          />
        </PinnedScrollbar>
      </Flex>
      <Flex
        width='100%'
        flexDirection='column'
      >
        <Divider />
        {
          R.not(isCarrier) &&
          <NavbarButton
            icon={I.pencil}
            iconsColor={iconsColor}
            editPinned={editPinned}
            onClick={toggleEditPinned}
            text={G.getWindowLocale('action:edit', 'Edit Panel')}
          />
        }
      </Flex>
    </Flex>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  styling: makeSelectStyling(state),
  sidebar: makeSelectSidebar(state),
  pinnedItemsList: makeSelectPinnedMenuItems(state),
});

export default connect(mapStateToProps, {
  reorderPinnedItems,
  removePinnedItemFromList,
})(PinnedItems);

import React from 'react';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Flex, IconWrapper } from '../../../ui';
//////////////////////////////////////////////////

const darkGrayColor = G.getTheme('colors.darkGrey');
const darkBlueColor = G.getTheme('colors.dark.blue');

const RowActions = ({ handleEditInvoice, handleRemoveInvoice }: Object) => (
  <Flex mr={15} width={40} justifyContent='space-between'>
    <IconWrapper cursor='pointer' onClick={handleEditInvoice}>
      {I.pencil(darkBlueColor)}
    </IconWrapper>
    <IconWrapper cursor='pointer' onClick={handleRemoveInvoice}>
      {I.trash(darkBlueColor)}
    </IconWrapper>
  </Flex>
);

const InvoiceInfoPair = ({ text }: Object) => (
  <TextComponent
    mr={10}
    title={text}
    maxWidth={150}
    display='block'
    withEllipsis={true}
    color={darkGrayColor}
  >
    {text}
  </TextComponent>
);

const TotalInfo = ({ mr, costs, titleLocaleArr, currencySymbol }: Object) => {
  const total = G.calculateTotal(costs);
  const title = `${G.getWindowLocale(...titleLocaleArr)}: ${currencySymbol} ${total}`;

  return (
    <TextComponent
      mr={mr}
      title={title}
      maxWidth={120}
      display='block'
      withEllipsis={true}
    >
      {title}
    </TextComponent>
  );
};

export const InvoiceInfo = (props: Object) => {
  const { invoice, handleEditInvoice, handleRemoveInvoice } = props;

  const { fees, parts, total, labors, currency, invoiceDate, invoiceNumber } = invoice;

  const currencySymbol = G.getCurrencySymbol(currency);

  const invoiceNumberText = `#: ${invoiceNumber}`;
  const invoiceDateText = `${G.getWindowLocale('titles:date', 'Date')}: ${invoiceDate}`;
  const invoiceTotalText = `${G.getWindowLocale('titles:total', 'Total')}: ${currencySymbol} ${total}`;

  return (
    <Flex mb={15} alignItems='flex-start'>
      <RowActions handleEditInvoice={handleEditInvoice} handleRemoveInvoice={handleRemoveInvoice} />
      <Flex width='100%' alignItems='flex-start' flexDirection='column'>
        {
          G.isOneNotNilOrNotEmpty([invoiceDate, invoiceNumber]) &&
          <Flex mb={10}>
            {G.isNotNilAndNotEmpty(invoiceNumber) && <InvoiceInfoPair text={invoiceNumberText} />}
            {G.isNotNilAndNotEmpty(invoiceDate) && <InvoiceInfoPair text={invoiceDateText} />}
          </Flex>
        }
        <Flex mb={10}>
          <TotalInfo
            mr={10}
            costs={labors}
            currencySymbol={currencySymbol}
            titleLocaleArr={['titles:labor', 'Labor']}
          />
          <TotalInfo
            mr={10}
            costs={parts}
            currencySymbol={currencySymbol}
            titleLocaleArr={['titles:parts', 'Parts']}
          />
          <TotalInfo
            costs={fees}
            currencySymbol={currencySymbol}
            titleLocaleArr={['titles:fees', 'Fees']}
          />
        </Flex>
        <Flex pr={10} width='100%' justifyContent='flex-end'>
          <TextComponent
            maxWidth={300}
            display='block'
            fontWeight={700}
            withEllipsis={true}
            color={darkGrayColor}
            title={invoiceTotalText}
            textTransform='uppercase'
          >
            {invoiceTotalText}
          </TextComponent>
        </Flex>
      </Flex>
    </Flex>
  );
};

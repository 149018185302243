/* eslint-disable no-param-reassign */
import * as R from 'ramda';
import { eventChannel } from 'redux-saga';
import { all, put, call, take, fork, race, delay } from 'redux-saga/effects';
// features
import { refreshTokenCatch, refreshTokenRequest, refreshTokenSuccess } from '../../auth/actions';
// helpers/constants
import * as G from '../../../helpers';
// utilities
import endpointsMap from '../../../utilities/endpoints';
// feature sockets
import * as A from '../actions';
import * as LC from '../constants';
import {
  clientTopics,
  openStompClient,
  waitForSocketConnected,
  checkClientSocketDisconnect,
  watchSendStompMessageRequestSaga,
} from '../helpers';
//////////////////////////////////////////////////

function* watchEventChannelMessagesSaga(eventChannel: Object) {
  while (true) { // eslint-disable-line
    const event = yield take(eventChannel);

    const { payload } = event;

    const { body } = payload;

    const { socketDataType } = body;

    switch (socketDataType) {
      case LC.SOCKET_DATA_TYPE_DRIVER_CARDS_TEL:
        yield put(A.socketDriverCardsTelReceived(body));

        break;

      default:
        console.warn(`watchEventChannelMessagesSaga - Unknown socket data type: ${socketDataType}`, body);
        break;
    }
  }
}

const runStompClientSub = (
  client: Object,
  { accessToken, masterEnterpriseGuid }: Object,
) => eventChannel((emit: Function) => {
  const topic = clientTopics.getDriverCardsTel(masterEnterpriseGuid);

  client.subscribe(topic, (message: Object) => {
    const body = JSON.parse(message.body);

    console.info('///-runStompClientSub-message-body', body);

    emit({
      payload: {
        body,
      },
    });
  }, { 'access-token': accessToken });

  return () => client.deactivate({ 'access-token': accessToken });
});

function* reconnectSocketSaga(action: Object) {
  yield delay(LC.SocketReconnectDelay);

  if (G.isAuthTokenExpired()) {
    yield put(refreshTokenRequest());

    yield race({
      success: take(refreshTokenSuccess),
      catchAction: take(refreshTokenCatch),
    });
  }

  const options = {
    endpoint: endpointsMap.routeSocket,
    masterEnterpriseGuid: G.getAmousCurrentBranchMasterGuidFromWindow(),
  };

  if (G.isNotNil(action)) {
    yield put(A.socketDriverCardsTelReconnectRequest(options));
  }
}

function* watchSocketSaga() {
  try {
    while (true) { // eslint-disable-line
      const { connectAction, reconnectAction } = yield race({
        connectAction: take(A.socketDriverCardsTelConnectRequest),
        reconnectAction: take(A.socketDriverCardsTelReconnectRequest),
      });

      const action = R.or(connectAction, reconnectAction);

      const data = {
        reconnectDelay: 0,
        endpoint: endpointsMap.routeSocket,
        accessToken: G.getAuthTokenFromSession(),
        masterEnterpriseGuid: G.getAmousCurrentBranchMasterGuidFromWindow(),
      };

      const client = yield call(openStompClient, data);

      yield fork(waitForSocketConnected, client, LC.DRIVER_CARDS_TEL_SOCKET_TYPE, A.socketDriverCardsTelConnected);

      const { payload: connected } = yield take(A.socketDriverCardsTelConnected);

      if (connected) {
        const eventChannel = yield call(runStompClientSub, client, data);

        const { cancel } = yield race({
          cancel: take(A.socketDriverCardsTelDisconnectRequest),
          task: all([
            call(checkClientSocketDisconnect, client, A.socketDriverCardsTelDisconnectRequest),
            call(watchEventChannelMessagesSaga, eventChannel),
            call(watchSendStompMessageRequestSaga, client),
          ]),
        });

        if (cancel) eventChannel.close();

        if (R.not(R.pathEq('deactivate', ['payload'], cancel))) {
          yield fork(reconnectSocketSaga, action);
        }
      } else {
        yield fork(reconnectSocketSaga, action);
      }
    }
  } catch (error) {
    console.log('///////////////////////////////////////', 'catch watchSocketSaga');
  }
}

function* socketDriverCardsTelWatcherSaga() {
  yield fork(watchSocketSaga);
}

export default socketDriverCardsTelWatcherSaga;

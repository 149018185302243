import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  withHandlers,
  renderNothing,
} from 'react-recompose';
// common
import { makeSelectSubmitLoading } from '../../common/selectors';
// components
import DimmerComponent from '../../components/loader/dimmer';
import { makeSelectLoader } from '../../components/loader/selectors';
import { openModal, closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { downloadEdiFileRequest } from '../edi-integration/actions';
import { makeSelectExpandedContainerOptions } from '../expanded-container/selectors';
import { getBranchConfigsByNamesRequest, openAddBranchModalRequest } from '../branch/actions';
// TODO: move this helper and form to global forms
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// forms
import SaveOrderTemplateForm from '../../forms/forms/save-order-template-form';
// ui
import { Box, Flex, PageWrapper } from '../../ui';
// utilities
import routesMap from '../../utilities/routes';
// feature new-do
import Header from './components/header';
import Footer from './components/footer';
import ErrorsComponent from './components/errors';
import LeftSection from './components/left-section';
import RightSection from './components/right-section';
import { withNextStartDatesForm } from './forms/next-start-dates';
import RouteTelForm, { getTelRouteModalOptions } from './forms/route-tel-form';
import {
  setBranchInfo,
  toggleHotOrder,
  setDivisionGuid,
  cleanNewDOStore,
  setValueToStore,
  setActiveLeftTab,
  setNumberOfLoads,
  setFormDataToStop,
  addNewStopToStore,
  setActiveRightTab,
  setIgnoreWarnings,
  setTelCreationMode,
  setFormDataToStore,
  setRatePreviewFiles,
  removeItemFromStore,
  setPrimaryReference,
  removeStopFromStore,
  getBranchDataRequest,
  getBranchListRequest,
  sendDataToApiRequest,
  setDriverRateToStore,
  setCustomerLoadNumber,
  saveAsTemplateRequest,
  setCarrierRateToStore,
  removeValidationErrors,
  copyTemplateDataToStore,
  removeValidationWarnings,
  setCustomerReferenceValue,
  getBranchDataOnQuoteRequest,
  applyOrderRateUpliftRequest,
  recalculateLoadDistancesRequest,
  recalculateLoadDistancesSuccess,
} from './actions';
import {
  makeSelectRate,
  makeSelectStops,
  makeSelectBillTo,
  makeSelectStyling,
  makeSelectEdiData,
  makeSelectEdiFile,
  makeSelectHotOrder,
  makeSelectLoadType,
  makeSelectPageType,
  makeSelectDivision,
  makeSelectOrderType,
  makeSelectLoadTotals,
  makeSelectSourceType,
  makeSelectBranchList,
  makeSelectBranchInfo,
  makeSelectBranchGuid,
  makeSelectDriverRate,
  makeSelectCarrierRate,
  makeSelectDivisionGuid,
  makeSelectTemplateData,
  makeSelectMapLocations,
  makeSelectLeftActiveTad,
  makeSelectBranchConfigs,
  makeSelectNumberOfLoads,
  makeSelectRightActiveTad,
  makeSelectBranchRefTypes,
  makeSelectTelCreationMode,
  makeSelectRouteTelConfigs,
  makeSelectPrimaryReference,
  makeSelectValidationErrors,
  makeSelectRatePreviewFiles,
  makeSelectStopPointsStrings,
  makeSelectRouteTemplateGuid,
  makeSelectReferenceFormData,
  makeSelectCustomerLoadNumber,
  makeSelectPrimaryRefTypeGuid,
  makeSelectPrimaryRefTypeName,
  makeSelectValidationWarnings,
  makeSelectCustomerRefTypeName,
  makeSelectIntegrationCarriers,
  makeSelectSaveAndDuplicateQty,
  makeSelectCustomerReferenceValue,
  makeSelectPrimaryRefSequenceValue,
  makeSelectTransportationModeGrouping,
} from './selectors';
//////////////////////////////////////////////////

const Body = (props: Object) => (
  <Flex
    p='15px 25px'
    flexWrap='wrap'
    alignItems='flex-start'
  >
    <LeftSection {...props} />
    <RightSection {...props} />
  </Flex>
);

const DOComponent = (props: Object) => (
  <Box>
    {props.loader.showDimmer && <DimmerComponent tableCount={3} rectangleCount={4} />}
    <Box overflow='auto' height='calc(100vh - 60px)'>
      <Header {...props} />
      {
        G.isNotNilAndNotEmpty(props.validationErrors) &&
        <ErrorsComponent
          type='error'
          errors={props.validationErrors}
          remove={props.removeValidationErrors}
          // TODO: check title with Mark ;)
          title='Oops! Looks like there are some mistakes, check the following:'
        />
      }
      {
        G.isNotNilAndNotEmpty(props.validationWarnings) &&
        <ErrorsComponent
          type='warning'
          errors={props.validationWarnings}
          remove={props.removeValidationWarnings}
          showActions={G.isNilOrEmpty(props.validationErrors)}
          title={G.getWindowLocale('titles:warnings', 'Warnings')}
          actions={[
            {
              action: () => props.setIgnoreWarnings(true),
              text: G.getWindowLocale('actions:ignore-warnings', 'Ignore Warnings'),
            },
          ]}
        />
      }
      <Body {...props} />
    </Box>
    <Footer {...props} />
  </Box>
);

const NewDOComponent = (props: Object) => (
  <PageWrapper pb='0' pl={R.pathOr('51', ['pageWrapperPaddingLeft'], props)} bgColor={G.getTheme('colors.bgGrey')}>
    <DOComponent {...props} />
  </PageWrapper>
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  rate: makeSelectRate(state),
  stops: makeSelectStops(state),
  billTo: makeSelectBillTo(state),
  loader: makeSelectLoader(state),
  styling: makeSelectStyling(state),
  ediFile: makeSelectEdiFile(state),
  ediData: makeSelectEdiData(state),
  loadType: makeSelectLoadType(state),
  pageType: makeSelectPageType(state),
  division: makeSelectDivision(state),
  hotOrder: makeSelectHotOrder(state),
  orderType: makeSelectOrderType(state),
  branchGuid: makeSelectBranchGuid(state),
  loadTotals: makeSelectLoadTotals(state),
  branchList: makeSelectBranchList(state),
  branchInfo: makeSelectBranchInfo(state),
  sourceType: makeSelectSourceType(state),
  driverRate: makeSelectDriverRate(state),
  carrierRate: makeSelectCarrierRate(state),
  divisionGuid: makeSelectDivisionGuid(state),
  templateData: makeSelectTemplateData(state),
  mapLocations: makeSelectMapLocations(state),
  submitLoading: makeSelectSubmitLoading(state),
  numberOfLoads: makeSelectNumberOfLoads(state),
  leftActiveTad: makeSelectLeftActiveTad(state),
  branchConfigs: makeSelectBranchConfigs(state),
  rightActiveTad: makeSelectRightActiveTad(state),
  branchRefTypes: makeSelectBranchRefTypes(state),
  telCreationMode: makeSelectTelCreationMode(state),
  routeTelConfigs: makeSelectRouteTelConfigs(state),
  validationErrors: makeSelectValidationErrors(state),
  primaryReference: makeSelectPrimaryReference(state),
  ratePreviewFiles: makeSelectRatePreviewFiles(state),
  stopPointsStrings: makeSelectStopPointsStrings(state),
  routeTemplateGuid: makeSelectRouteTemplateGuid(state),
  referenceFormData: makeSelectReferenceFormData(state),
  currentBranchGuid: makeSelectCurrentBranchGuid(state),
  customerLoadNumber: makeSelectCustomerLoadNumber(state),
  primaryRefTypeGuid: makeSelectPrimaryRefTypeGuid(state),
  primaryRefTypeName: makeSelectPrimaryRefTypeName(state),
  validationWarnings: makeSelectValidationWarnings(state),
  integrationCarriers: makeSelectIntegrationCarriers(state),
  saveAndDuplicateQty: makeSelectSaveAndDuplicateQty(state),
  customerRefTypeName: makeSelectCustomerRefTypeName(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
  customerReferenceValue: makeSelectCustomerReferenceValue(state),
  primaryRefSequenceValue: makeSelectPrimaryRefSequenceValue(state),
  expandedContainerOptions: makeSelectExpandedContainerOptions(state),
  transportationModeGroupingList: makeSelectTransportationModeGrouping(state),
});

const enhance = compose(
  connect(mapStateToProps, {
    openModal,
    closeModal,
    openLoader,
    closeLoader,
    setBranchInfo,
    toggleHotOrder,
    setDivisionGuid,
    setValueToStore,
    cleanNewDOStore,
    setActiveLeftTab,
    setNumberOfLoads,
    setFormDataToStop,
    addNewStopToStore,
    setActiveRightTab,
    setIgnoreWarnings,
    setTelCreationMode,
    setFormDataToStore,
    setRatePreviewFiles,
    setPrimaryReference,
    removeStopFromStore,
    removeItemFromStore,
    getBranchListRequest,
    sendDataToApiRequest,
    getBranchDataRequest,
    setDriverRateToStore,
    saveAsTemplateRequest,
    setCustomerLoadNumber,
    setCarrierRateToStore,
    removeValidationErrors,
    downloadEdiFileRequest,
    copyTemplateDataToStore,
    removeValidationWarnings,
    setCustomerReferenceValue,
    openAddBranchModalRequest,
    getBranchDataOnQuoteRequest,
    applyOrderRateUpliftRequest,
    getBranchConfigsByNamesRequest,
    recalculateLoadDistancesRequest,
    recalculateLoadDistancesSuccess,
  }),
  withNextStartDatesForm,
  withHandlers({
    handleClickSave: (props: Object) => (data: any) => {
      const {
        openModal,
        closeModal,
        branchGuid,
        routeTelConfigs,
        telCreationMode,
        customerLoadNumber,
        sendDataToApiRequest,
      } = props;

      const { autoRouteName, autoTelPrimaryRef, telPrimCopyFromClo } = routeTelConfigs;

      const quote = R.path(['quote'], data);

      if (R.equals(telCreationMode, GC.TEL_CREATION_MODE_NO_TEL)) {
        return sendDataToApiRequest({ quote });
      }

      if (G.shouldHideRouteTelModalOnCreateClo(routeTelConfigs)) {
        return sendDataToApiRequest({ quote });
      }

      const modalContent = (
        <RouteTelForm
          closeModal={closeModal}
          branchGuid={branchGuid}
          autoRouteName={autoRouteName}
          scopeName={GC.REF_SCOPE_NAME_TEL}
          autoTelPrimaryRef={autoTelPrimaryRef}
          telPrimCopyFromClo={telPrimCopyFromClo}
          customerLoadNumber={customerLoadNumber}
          createLoadRequest={sendDataToApiRequest}
        />
      );

      const modal = getTelRouteModalOptions(modalContent);

      return openModal(modal);
    },
    handleSaveAsTemplate: (props: Object) => () => {
      const { openModal, closeModal, saveAsTemplateRequest } = props;

      const component = (
        <SaveOrderTemplateForm
          submitAction={(data: Object) => {
            closeModal();
            saveAsTemplateRequest(data);
          }}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 300,
          height: 'auto',
          maxHeight: '95vh',
          title: G.getWindowLocale('actions:create-clo-template', 'Create CLO Template'),
        },
      };

      openModal(modal);
    },
    handleClickCancel: (props: Object) => () => {
      const { expandedContainerOptions, closeExpandedContainer } = props;

      if (R.pathEq(true, ['opened'], expandedContainerOptions)) {
        G.callFunctionWithArgs(closeExpandedContainer, true);
      } else {
        G.goToRoute(routesMap.routeListOrder);
      }
    },
    handleChangeSearchInput: (props: Object) => (select: any) => {
      const { cleanNewDOStore, getBranchDataRequest } = props;

      if (R.isNil(select)) {
        return cleanNewDOStore({ sourceType: GC.CREATE_DO_SOURCE_TYPE_MANUAL });
      }

      const branchGuid = select.value;

      cleanNewDOStore({
        [GC.FIELD_GUID]: branchGuid,
        [GC.FIELD_BRANCH_NAME]: select.label,
        sourceType: GC.CREATE_DO_SOURCE_TYPE_MANUAL,
      });

      getBranchDataRequest(branchGuid);
    },
    handleChangeSearchInputOnQuote: (props: Object) => (select: any) => {
      const { setBranchInfo, getBranchDataOnQuoteRequest } = props;

      if (R.isNil(select)) return;

      setBranchInfo(select);

      const branchGuid = select.value;

      getBranchDataOnQuoteRequest(branchGuid);
    },
    handleChangeBranchRefTypeInput: ({ setPrimaryReference }: Object) => (select: any) => (
      setPrimaryReference(select)
    ),
    handleChangeCustomerLoadNumberInput: ({ setCustomerLoadNumber }: Object) => (value: string) => (
      setCustomerLoadNumber(value)
    ),
    handleChangeCustomerReferenceValueInput: ({ setCustomerReferenceValue }: Object) => (value: string) => (
      setCustomerReferenceValue(value)
    ),
  }),
  branch(
    (props: Object) => R.not(props.initialDataLoaded),
    renderNothing,
  ),
  pure,
);

export const Component = enhance(NewDOComponent);

export default enhance(NewDOComponent);

export const MaxSocketReconnectTries = 6;
export const SocketReconnectDelay = 30000;
// SOCKET TYPE
export const TEL_SOCKET_TYPE = 'tel-socket';
export const IMPORT_SOCKET_TYPE = 'import-socket';
export const LB_STATE_SOCKET_TYPE = 'lb-state-socket';
export const LB_RESULT_SOCKET_TYPE = 'lb-result-socket';
export const USER_ERROR_SOCKET_TYPE = 'user-error-socket';
export const DRIVER_CARDS_TEL_SOCKET_TYPE = 'driver-cards-tel';
export const CARRIER_PORTAL_SOCKET_TYPE = 'carrier-portal-socket';
export const USER_NOTIFICATION_SOCKET_TYPE = 'user-notification-socket';
export const USER_MASS_ACTION_ERROR_SOCKET_TYPE = 'user-mass-action-error-socket';
export const IFTA_DOCUMENT_GENERATED_SOCKET_TYPE = 'ifta-document-generated-socket';
export const USER_DOCUMENT_GENERATED_SOCKET_TYPE = 'user-document-generated-socket';
export const FLEET_DOCUMENT_GENERATED_SOCKET_TYPE = 'fleet-document-generated-socket';
export const USER_ERROR_FROM_CARRIER_SOCKET_TYPE = 'user-error-from-carrier-socket';
export const ROUTE_DOCUMENT_GENERATED_SOCKET_TYPE = 'route-document-generated-socket';
export const BRANCH_DOCUMENT_GENERATED_SOCKET_TYPE = 'branch-document-generated-socket';
export const CARRIER_DOCUMENT_GENERATED_SOCKET_TYPE = 'carrier-document-generated-socket';
export const STATISTIC_DOCUMENT_GENERATED_SOCKET_TYPE = 'statistic-document-generated-socket';
export const TEMPLATES_DOCUMENT_GENERATED_SOCKET_TYPE = 'templates-document-generated-socket';
// DATA TYPE
export const SOCKET_DATA_TYPE_IFTA = 'IFTA';
export const SOCKET_DATA_TYPE_RATE = 'RATE';
export const SOCKET_DATA_TYPE = 'socketDataType';
export const SOCKET_DATA_TYPE_DOCUMENT = 'DOCUMENT';
export const SOCKET_DATA_TYPE_LOAD_STATUS = 'LOAD_STATUS';
export const SOCKET_DATA_TYPE_RATE_STATUS = 'RATE_STATUS';
export const SOCKET_DATA_TYPE_FRONT_ERROR = 'FRONT_ERROR';
export const SOCKET_DATA_TYPE_CHAT_MESSAGE = 'CHAT_MESSAGE';
export const SOCKET_DATA_TYPE_EVENT_STATUS = 'EVENT_STATUS';
export const SOCKET_DATA_TYPE_STATUS_MESSAGE = 'STATUS_MESSAGE';
export const SOCKET_DATA_TYPE_COST_ALLOCATION = 'COST_ALLOCATION';
export const SOCKET_DATA_TYPE_DRIVER_CARDS_TEL = 'DRIVER_CARDS_TEL';
export const SOCKET_DATA_TYPE_TEL_STATUS_TO_CLO = 'TEL_STATUS_TO_CLO';
export const SOCKET_DATA_TYPE_CLO_STATUS_TO_TEL = 'CLO_STATUS_TO_TEL';
export const SOCKET_DATA_TYPE_EDI_EXPORT_RESULT = 'EDI_EXPORT_RESULT';
export const SOCKET_DATA_TYPE_EXPORT_TO_ACCOUNTING = 'EXPORT_TO_ACCOUNTING';
export const SOCKET_DATA_TYPE_GENERATED_DOCUMENT_URL = 'GENERATED_DOCUMENT_URL';
export const SOCKET_DATA_TYPE_AVAILABLE_DRIVERS_NOTE = 'AVAILABLE_DRIVERS_NOTE';
export const SOCKET_DATA_TYPE_AVAILABLE_DRIVERS_STATUS = 'AVAILABLE_DRIVERS_STATUS';
export const SOCKET_DATA_TYPE_AVAILABLE_DRIVERS_RESERVATION = 'AVAILABLE_DRIVERS_RESERVATION';
export const SOCKET_DATA_TYPE_AVAILABLE_DRIVERS_LAST_KNOWN_LOCATION = 'AVAILABLE_DRIVERS_LAST_KNOWN_LOCATION';
// CHANNEL MESSAGES
export const SOCKET_CHANNEL_ADD_MESSAGE = 'SOCKET_CHANNEL_ADD_MESSAGE';
export const SOCKET_CHANNEL_SOCKET_ERROR = 'SOCKET_CHANNEL_SOCKET_ERROR';
export const SOCKET_CHANNEL_SOCKET_CLOSE = 'SOCKET_CHANNEL_SOCKET_CLOSE';
export const SOCKET_CHANNEL_TEL_MESSAGE_RECEIVED = 'SOCKET_CHANNEL_TEL_MESSAGE_RECEIVED';
export const SOCKET_CHANNEL_IMPORT_MESSAGE_RECEIVED = 'SOCKET_CHANNEL_IMPORT_MESSAGE_RECEIVED';
export const SOCKET_CHANNEL_LOAD_BOARD_STATE_RECEIVED = 'SOCKET_CHANNEL_LOAD_BOARD_STATE_RECEIVED';
export const SOCKET_CHANNEL_LOAD_BOARD_RESULT_RECEIVED = 'SOCKET_CHANNEL_LOAD_BOARD_RESULT_RECEIVED';
export const SOCKET_CHANNEL_AVAILABLE_DRIVERS_RECEIVED = 'SOCKET_CHANNEL_AVAILABLE_DRIVERS_RECEIVED';
export const SOCKET_CHANNEL_USER_ERROR_MESSAGE_RECEIVED = 'SOCKET_CHANNEL_USER_ERROR_MESSAGE_RECEIVED';
export const SOCKET_CHANNEL_LOAD_BOARD_CONFIG_STATE_RECEIVED = 'SOCKET_CHANNEL_LOAD_BOARD_CONFIG_STATE_RECEIVED';
export const SOCKET_CHANNEL_LOAD_BOARD_FILTER_STATE_RECEIVED = 'SOCKET_CHANNEL_LOAD_BOARD_FILTER_STATE_RECEIVED';
export const SOCKET_CHANNEL_TEL_FOR_CARRIER_MESSAGE_RECEIVED = 'SOCKET_CHANNEL_TEL_FOR_CARRIER_MESSAGE_RECEIVED';
export const SOCKET_CHANNEL_USER_NOTIFICATION_MESSAGE_RECEIVED = 'SOCKET_CHANNEL_USER_NOTIFICATION_MESSAGE_RECEIVED';
export const SOCKET_CHANNEL_AVAILABLE_DRIVERS_ALL_DRIVERS_RECEIVED =
'SOCKET_CHANNEL_AVAILABLE_DRIVERS_ALL_DRIVERS_RECEIVED';
export const SOCKET_CHANNEL_USER_ERROR_FROM_CARRIER_MESSAGE_RECEIVED =
'SOCKET_CHANNEL_USER_ERROR_FROM_CARRIER_MESSAGE_RECEIVED';
export const SOCKET_CHANNEL_USER_MASS_ACTION_ERROR_MESSAGE_RECEIVED =
'SOCKET_CHANNEL_USER_MASS_ACTION_ERROR_MESSAGE_RECEIVED';
export const SOCKET_CHANNEL_LOAD_BOARD_POSTED_SHIPMENT_STATE_RECEIVED =
  'SOCKET_CHANNEL_LOAD_BOARD_POSTED_SHIPMENT_STATE_RECEIVED';
export const SOCKET_CHANNEL_LOAD_BOARD_BOOKED_SHIPMENT_STATE_RECEIVED =
  'SOCKET_CHANNEL_LOAD_BOARD_BOOKED_SHIPMENT_STATE_RECEIVED';
export const SOCKET_CHANNEL_USER_DOCUMENT_GENERATION_MESSAGE_RECEIVED =
  'SOCKET_CHANNEL_USER_DOCUMENT_GENERATION_MESSAGE_RECEIVED';
export const SOCKET_CHANNEL_ROUTE_DOCUMENT_GENERATION_MESSAGE_RECEIVED =
  'SOCKET_CHANNEL_ROUTE_DOCUMENT_GENERATION_MESSAGE_RECEIVED';
export const SOCKET_CHANNEL_IFTA_DOCUMENT_GENERATION_MESSAGE_RECEIVED =
  'SOCKET_CHANNEL_IFTA_DOCUMENT_GENERATION_MESSAGE_RECEIVED';
export const SOCKET_CHANNEL_CARRIER_DOCUMENT_GENERATION_MESSAGE_RECEIVED =
  'SOCKET_CHANNEL_CARRIER_DOCUMENT_GENERATION_MESSAGE_RECEIVED';
export const SOCKET_CHANNEL_BRANCH_DOCUMENT_GENERATION_MESSAGE_RECEIVED =
  'SOCKET_CHANNEL_BRANCH_DOCUMENT_GENERATION_MESSAGE_RECEIVED';
export const SOCKET_CHANNEL_FLEET_DOCUMENT_GENERATION_MESSAGE_RECEIVED =
  'SOCKET_CHANNEL_FLEET_DOCUMENT_GENERATION_MESSAGE_RECEIVED';
export const SOCKET_CHANNEL_STATISTIC_DOCUMENT_GENERATION_MESSAGE_RECEIVED =
  'SOCKET_CHANNEL_STATISTIC_DOCUMENT_GENERATION_MESSAGE_RECEIVED';
export const SOCKET_CHANNEL_TEMPLATES_DOCUMENT_GENERATION_MESSAGE_RECEIVED =
  'SOCKET_CHANNEL_TEMPLATES_DOCUMENT_GENERATION_MESSAGE_RECEIVED';

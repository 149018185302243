import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { compose } from 'react-recompose';
// components
import { ChargeFormFooter } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, scrollableContainerCss4px } from '../../../ui';
// feature work-order
import { CostsSection, validationSchemaCostsObject } from './costs-section';
//////////////////////////////////////////////////

const validationSchema = Yup.object()
  .shape(validationSchemaCostsObject)
  .shape({
    [GC.FIELD_CURRENCY]: G.yupStringRequired,
    [GC.FIELD_INVOICE_NUMBER]: G.yupStringNotRequired.max(40, G.getShouldBeFromToLocaleTxt(0, 40)),
    [GC.FIELD_DESCRIPTION]: G.yupStringNotRequired.max(2000, G.getShouldBeFromToLocaleTxt(0, 2000)),
  });

const invoiceInputWrapperStyles = { mb: 25, width: 220 };

const invoiceFieldSettings = [
  {
    type: 'text',
    fieldName: GC.FIELD_INVOICE_NUMBER,
    inputWrapperStyles: invoiceInputWrapperStyles,
    label: ['titles:invoice-number', 'Invoice #'],
  },
  {
    isClearable: true,
    type: 'datePicker',
    fieldName: GC.FIELD_INVOICE_DATE,
    inputWrapperStyles: invoiceInputWrapperStyles,
    label: ['titles:invoice-date', 'Invoice Date'],
  },
  {
    type: 'select',
    isRequired: true,
    fieldName: GC.FIELD_CURRENCY,
    options: GC.CURRENCY_OPTIONS,
    label: ['titles:currency', 'Currency'],
    inputWrapperStyles: invoiceInputWrapperStyles,
  },
  {
    type: 'textarea',
    fieldName: GC.FIELD_DESCRIPTION,
    label: ['titles:description', 'Description'],
    inputWrapperStyles: { mb: 20, width: '100%' },
  },
];

const defaultValues = {
  [GC.FIELD_DESCRIPTION]: null,
  [GC.FIELD_INVOICE_DATE]: null,
  [GC.FIELD_INVOICE_NUMBER]: null,
  [GC.FIELD_CURRENCY]: R.or(
    G.getAmousConfigByNameFromWindow(GC.GENERAL_BRANCH_DEFAULT_CURRENCY),
    GC.DEFAULT_UI_CURRENCY,
  ),
};

const formEnhance = compose(
  withFormik({
    validationSchema,
    handleSubmit: (values: Object, { props: { submitAction } }: Object) => submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(defaultValues, initialValues),
  }),
);

export const InvoiceForm = formEnhance((props: Object) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Box overflow='auto' maxHeight='calc(87vh - 50px)' css={scrollableContainerCss4px}>
        <Fieldset2
          {...G.getFormikProps(props)}
          fields={invoiceFieldSettings}
          fieldsWrapperStyles={{ px: 15, pt: 25, justifyContent: 'space-between' }}
        />
        <CostsSection {...props} />
      </Box>
      <ChargeFormFooter />
    </form>
  );
});

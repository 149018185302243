import * as R from 'ramda';
import { connect } from 'react-redux';
import React, { Fragment } from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../components/confirm';
import { FormSectionHeader } from '../../../components/form-section-header';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex, scrollableContainerCss4px } from '../../../ui';
// feature work-order
import { InvoiceInfo } from './invoice-info';
import { InvoiceForm } from './invoice-form';
import { isSingleCurrency } from '../helpers';
import { TotalComponent } from './total-component';
import { deleteWorkOrderInvoiceRequest, createOrUpdateWorkOrderInvoiceRequest } from '../actions';
//////////////////////////////////////////////////

const enhance = compose(
  connect(null, { deleteWorkOrderInvoiceRequest, createOrUpdateWorkOrderInvoiceRequest }),
  withHandlers({
    handleAddOrEditInvoice: (props: Object) => (invoice: Object) => {
      const { guid, invoices, openModal, entityType, setInvoices, createOrUpdateWorkOrderInvoiceRequest } = props;

      const isEditMode = G.isNotNilAndNotEmpty(invoice);
      const initialValues = G.ifElse(isEditMode, invoice, null);

      const submitAction = (values: Object) => createOrUpdateWorkOrderInvoiceRequest({
        isEditMode,
        entityType,
        values: R.assoc(GC.FIELD_WORK_ORDER_GUID, guid, values),
        successCallback: (data: Object) => setInvoices(R.assoc(G.getGuidFromObject(data), data, invoices)),
      });

      const component = (
        <InvoiceForm
          isEditMode={isEditMode}
          submitAction={submitAction}
          initialValues={initialValues}
        />
      );

      const title = G.ifElse(
        isEditMode,
        G.getEditTitle,
        G.getAddTitle,
      )(['titles:costs-invoice', 'Costs / Invoice']);

      const modal = {
        p: '0px',
        component,
        options: { title, width: 780 },
      };

      openModal(modal);
    },
    handleRemoveInvoice: (props: Object) => (guid: string) => {
      const {
        invoices,
        openModal,
        closeModal,
        entityType,
        setInvoices,
        guid: workOrderGuid,
        deleteWorkOrderInvoiceRequest,
      } = props;

      const component = (
        <ConfirmComponent
          textLocale={G.getWindowLocale('messages:delete-confirmation-invoice', 'Do you want to delete this Invoice?')}
        />
      );

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:remove', 'Remove'),
              action: () => {
                closeModal();

                deleteWorkOrderInvoiceRequest({
                  guid,
                  entityType,
                  workOrderGuid,
                  successCallback: () => setInvoices(R.dissoc(guid, invoices)),
                });
              },
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const TotalsSection = ({ invoicesList }: Object) => {
  const isSingleInvoiceCurrency = isSingleCurrency(invoicesList);
  const isSingleNormalizedCurrency = isSingleCurrency(R.map(R.prop(GC.FIELD_NORMALIZED_TOTAL), invoicesList));

  const currencySymbol = G.getCurrencySymbol(R.path([0, GC.FIELD_CURRENCY], invoicesList));

  const normalizedCurrencySymbol = G.getCurrencySymbol(
    R.path([0, GC.FIELD_NORMALIZED_TOTAL, GC.FIELD_CURRENCY], invoicesList),
  );

  const showNormalizedTotal = R.and(
    isSingleNormalizedCurrency,
    R.or(
      R.not(isSingleInvoiceCurrency),
      R.and(isSingleInvoiceCurrency, G.notEquals(currencySymbol, normalizedCurrencySymbol)),
    ),
  );

  if (G.isAllFalse(showNormalizedTotal, isSingleInvoiceCurrency)) return null;

  const normalizedTotal = G.mathRoundNumber(R.reduce(
    (acc: number, { normalizedTotal }: Object) => R.add(acc, R.pathOr(0, [GC.FIELD_TOTAL], normalizedTotal)),
    0,
    invoicesList,
  ));

  const total = G.calculateTotal(invoicesList);

  return (
    <Flex p='5px' width='100%' justifyContent='center' bg={G.getTheme('colors.whiteGrey')}>
      {
        showNormalizedTotal &&
        <TotalComponent
          maxWidth={250}
          totalText={`${G.getWindowLocale('titles:normalized-total', 'Normalized Total')}: ${
            normalizedCurrencySymbol} ${normalizedTotal}`}
        />
      }
      {
        isSingleInvoiceCurrency &&
        <TotalComponent
          ml={10}
          maxWidth={150}
          totalText={`${G.getWindowLocale('titles:total', 'Total')}: ${currencySymbol} ${total}`}
        />
      }
    </Flex>
  );
};

export const InvoicesSection = enhance((props: Object) => {
  const { invoices, handleRemoveInvoice, handleAddOrEditInvoice } = props;

  const invoicesList = R.values(invoices);

  return (
    <FormSectionHeader
      expanded={true}
      action={() => handleAddOrEditInvoice()}
      title={G.getWindowLocale('titles:costs-invoices', 'Costs / Invoices')}
    >
      {
        G.isNotNilAndNotEmpty(invoices) &&
        <Fragment>
          <TotalsSection invoicesList={invoicesList} />
          <Box m={15} overflow='auto' maxHeight={200} css={scrollableContainerCss4px}>
            {
              invoicesList.map((invoice: any) => {
                const { guid } = invoice;

                return (
                  <InvoiceInfo
                    key={guid}
                    invoice={invoice}
                    handleRemoveInvoice={() => handleRemoveInvoice(guid)}
                    handleEditInvoice={() => handleAddOrEditInvoice(invoice)}
                  />
                );
              })
            }
          </Box>
        </Fragment>
      }
    </FormSectionHeader>
  );
});

import { createAction } from 'redux-act';
// report-common
import { getReportActions } from '../../report-common';
//////////////////////////////////////////////////

const {
  setReports,
  selectItem,
  setReportType,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  deleteItemRequest,
  deleteItemSuccess,
  setTableTitleSort,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  exportReportDataRequest,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
} = getReportActions();

const setDocumentsFilter = createAction('setDocumentsFilter');
const getServiceVendorSuccess = createAction('getServiceVendorSuccess');
const updateServiceVendorRequest = createAction('updateServiceVendorRequest');
const updateServiceVendorSuccess = createAction('updateServiceVendorSuccess');
const createServiceVendorRequest = createAction('createServiceVendorRequest');
const toggleServiceVendorFormGroup = createAction('toggleServiceVendorFormGroup');
const sendListToIntegrationRequest = createAction('sendListToIntegrationRequest');
const sendListToIntegrationSuccess = createAction('sendListToIntegrationSuccess');
const deleteServiceVendorDocRequest = createAction('deleteServiceVendorDocRequest');
const deleteServiceVendorDocSuccess = createAction('deleteServiceVendorDocSuccess');
const getServiceVendorDocListRequest = createAction('getServiceVendorDocListRequest');
const getServiceVendorDocListSuccess = createAction('getServiceVendorDocListSuccess');
const downloadServiceVendorDocRequest = createAction('downloadServiceVendorDocRequest');
const getDataForCompensationTabRequest = createAction('getDataForCompensationTabRequest');
const createOrUpdateServiceVendorDocRequest = createAction('createOrUpdateServiceVendorDocRequest');
const createOrUpdateServiceVendorDocSuccess = createAction('createOrUpdateServiceVendorDocSuccess');
const getServiceVendorIntegrationListRequest = createAction('getServiceVendorIntegrationListRequest');
const getServiceVendorIntegrationListSuccess = createAction('getServiceVendorIntegrationListSuccess');
const getServiceVendorIntegrationAuditListRequest = createAction('getServiceVendorIntegrationAuditListRequest');
const getServiceVendorIntegrationAuditListSuccess = createAction('getServiceVendorIntegrationAuditListSuccess');
// configs
const getServiceVendorConfigsRequest = createAction('getServiceVendorConfigsRequest');
const getServiceVendorConfigsSuccess = createAction('getServiceVendorConfigsSuccess');

export {
  // report
  setReports,
  selectItem,
  setReportType,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  deleteItemRequest,
  deleteItemSuccess,
  setTableTitleSort,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  exportReportDataRequest,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
  // service vendor
  setDocumentsFilter,
  getServiceVendorSuccess,
  updateServiceVendorRequest,
  updateServiceVendorSuccess,
  createServiceVendorRequest,
  toggleServiceVendorFormGroup,
  sendListToIntegrationRequest,
  sendListToIntegrationSuccess,
  deleteServiceVendorDocRequest,
  deleteServiceVendorDocSuccess,
  getServiceVendorDocListRequest,
  getServiceVendorDocListSuccess,
  downloadServiceVendorDocRequest,
  getDataForCompensationTabRequest,
  createOrUpdateServiceVendorDocRequest,
  createOrUpdateServiceVendorDocSuccess,
  getServiceVendorIntegrationListRequest,
  getServiceVendorIntegrationListSuccess,
  getServiceVendorIntegrationAuditListRequest,
  getServiceVendorIntegrationAuditListSuccess,
  // configs
  getServiceVendorConfigsRequest,
  getServiceVendorConfigsSuccess,
};


import * as R from 'ramda';
//////////////////////////////////////////////////

const apiDomain = process.env.API_DOMAIN;

const WebSocketURIsMap = {
  localDevelopment: 'wss://j4iowslwl9.execute-api.us-east-1.amazonaws.com/LATEST',
  localhost: 'wss://j4iowslwl9.execute-api.us-east-1.amazonaws.com/LATEST',
  development: 'wss://j4iowslwl9.execute-api.us-east-1.amazonaws.com/LATEST',
  testing: 'wss://ws.testamoustms.com',
  staging: 'wss://ws.testamoustms.com',
  production: 'wss://ws.amoustms.com',
};

const webSocketUri = R.prop(apiDomain, WebSocketURIsMap);

const messageType = {
  message: 'MESSAGE',
  subscribe: 'SUBSCRIBE',
  unsubscribe: 'UNSUBSCRIBE',
};

const socketTypes = {
  loadBoard: 'LOAD_BOARD',
};

const destinations = {
  [socketTypes.loadBoard]: /^\/(load-board)\//,
};

const loadBoardMessageType = {
  lbLogin: 'login',
  lbResult: 'load',
  lbConfig: 'load-board-config',
  committed: 'committed-shipment',
  posted: 'posted-shipment/state',
  filterState: 'search-filter/state',
};

export const SOCKET_OPEN = 'OPEN';
export const SOCKET_CLOSE = 'CLOSE';
export const SOCKET_ERROR = 'ERROR';
export const SOCKET_MESSAGE = 'MESSAGE';

export const MaxSocketReconnectTries = 6;
export const SocketReconnectDelay = 30000;

export {
  messageType,
  socketTypes,
  destinations,
  webSocketUri,
  loadBoardMessageType,
};

import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, withHandlers, renderNothing } from 'react-recompose';
// components
import { Table } from '../../../components/table';
import { TitlePanel } from '../../../components/title-panel';
import { EditReport } from '../../../components/edit-report';
import { getConfirmModal } from '../../../components/confirm';
import { PageActions } from '../../../components/page-actions';
import { ActionsElement } from '../../../components/actions-element';
import { withPromptModal } from '../../../components/edit-report/hocs';
import { transformPropDataFromSelectToString } from '../../../components/edit-report/helpers';
// features
import Audit2 from '../../audit2';
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { makeSelectInitialDataLoadedStatus } from '../../permission/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { reportEnhancer } from '../../../report-common';
import { withFixedPopover, withConnectModalAndLoaderActions } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { IconWrapper, ListWrapper, ZOrderWrapper, MainActionButton } from '../../../ui';
// feature work-order
import { FILTER_PARAMS, tableSettings, columnSettings } from './settings';
import { EditWorkOrderForm, CreateWorkOrderForm } from '../components/work-order-form';
import {
  makeSelectItemList,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from '../selectors';
import {
  setReports,
  setUsedReport,
  cleanQuickFilter,
  deleteItemRequest,
  setTableTitleSort,
  getItemListRequest,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  resetListAndPagination,
  changeDefaultReportRequest,
  changeWorkOrderStatusRequest,
  createOrUpdateWorkOrderRequest,
} from '../actions';
//////////////////////////////////////////////////

const getPermissions = (entity: Object, withReadPermission: boolean) => {
  const isTruck = G.isNotNilAndNotEmpty(G.getPropFromObject(GC.GRC.FLEET_TRUCK_GUID, entity));

  const truckPermissions = G.ifElse(
    withReadPermission,
    [PC.FLEET_TRUCK_READ, PC.FLEET_TRUCK_WRITE],
    [PC.FLEET_TRUCK_WRITE],
  );

  const trailerPermissions = G.ifElse(
    withReadPermission,
    [PC.FLEET_TRAILER_READ, PC.FLEET_TRAILER_WRITE],
    [PC.FLEET_TRAILER_WRITE],
  );

  return G.ifElse(isTruck, truckPermissions, trailerPermissions);
};

const RowActions = ({ entity, handleShowAudit }: Object) => {
  const options = [
    {
      action: () => handleShowAudit(entity),
      permissions: getPermissions(entity, true),
      text: G.getWindowLocale('titles:audit', 'Audit'),
      frontIcon: I.renderAuditHistoryIcon(G.getTheme('colors.dark.blue'), 15, 17),
    },
  ];

  return <ActionsElement options={options} entity={entity} />;
};

const enhance = compose(
  reportEnhancer,
  withFixedPopover,
  withConnectModalAndLoaderActions,
  withPromptModal(FILTER_PARAMS),
  withHandlers({
    handleCreateWorkOrder: (props: Object) => (
      entityType: string,
      primaryObjectGuid: string,
      primaryObjectUnitId: string,
    ) => {
      const {
        openModal,
        openLoader,
        closeLoader,
        currentBranchGuid,
        openedFromFleetProfile,
        createOrUpdateWorkOrderRequest,
      } = props;

      const isTruck = R.equals(entityType, GC.FIELD_TRUCK);

      const initialValues = G.isNotNilAndNotEmpty(primaryObjectGuid)
        ? { [G.ifElse(isTruck, GC.FIELD_TRUCK_GUID, GC.FIELD_TRAILER_GUID)]: primaryObjectGuid }
        : null;

      const component = (
        <CreateWorkOrderForm
          isTruck={isTruck}
          entityType={entityType}
          openLoader={openLoader}
          closeLoader={closeLoader}
          initialValues={initialValues}
          branchGuid={currentBranchGuid}
          fleetEntityGuid={primaryObjectGuid}
          fleetEntityUnitId={primaryObjectUnitId}
          submitAction={createOrUpdateWorkOrderRequest}
          openedFromFleetProfile={openedFromFleetProfile}
        />
      );

      const modal = {
        p: '0px',
        component,
        options: {
          title: G.getAddTitle(['titles:work-order', 'Work Order']),
        },
      };

      openModal(modal);
    },
    handleUpdateWorkOrder: (props: Object) => (guid: string, entity: Object) => {
      const {
        openModal,
        closeModal,
        openLoader,
        closeLoader,
        currentBranchGuid,
        createOrUpdateWorkOrderRequest,
      } = props;

      const isTruck = G.isNotNilAndNotEmpty(G.getPropFromObject(GC.GRC.FLEET_TRUCK_GUID, entity));
      const fleetEntityType = G.ifElse(isTruck, GC.FIELD_TRUCK, GC.FIELD_TRAILER);

      const component = (
        <EditWorkOrderForm
          guid={guid}
          isTruck={isTruck}
          openModal={openModal}
          closeModal={closeModal}
          openLoader={openLoader}
          closeLoader={closeLoader}
          entityType={fleetEntityType}
          branchGuid={currentBranchGuid}
          submitAction={createOrUpdateWorkOrderRequest}
          issueGuids={R.pathOr([], [GC.GRC.FLEET_SERVICE_ISSUES_GUID], entity)}
          fleetEntityGuid={G.getPropFromObject(`${fleetEntityType}.${GC.FIELD_GUID}`, entity)}
          fleetEntityUnitId={G.getPropFromObject(`${fleetEntityType}.${GC.FIELD_UNIT_ID}`, entity)}
        />
      );

      const modal = {
        p: '0px',
        component,
        options: {
          title: G.getEditTitle(['titles:work-order', 'Work Order']),
        },
      };

      openModal(modal);
    },
    handleDelete: ({ openModal, closeModal, deleteItemRequest }: Object) => (guid: string, entity: Object) => {
      const entityType = G.ifElse(
        G.isNotNilAndNotEmpty(G.getPropFromObject(GC.GRC.FLEET_TRUCK_GUID, entity)),
        GC.FIELD_TRUCK,
        GC.FIELD_TRAILER,
      );

      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitAction: () => deleteItemRequest({ guid, entityType }),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        text: G.getWindowLocale('messages:confirm-delete-entity', 'Are you sure you want to delete this entity?'),
      });

      openModal(modalContent);
    },
    handleShowAudit: (props: Object) => ({ guid, name }: string) => {
      const { openModal, closeFixedPopup } = props;

      closeFixedPopup();

      const requestOptions = {
        [GC.FIELD_OBJECT_GUID]: guid,
        [GC.FIELD_TYPE]: GC.AUDIT_TYPE_WORK_ORDER,
      };

      const component = <Audit2 requestOptions={requestOptions} />;

      const modal = {
        p: '0',
        component,
        options: {
          boxShadow: 'none',
          withCloseIcon: true,
          withBorderRadius: true,
          backgroundColor: 'transparent',
          title: `${G.getWindowLocale('titles:audit', 'Audit')}: ${name}`,
        },
      };

      openModal(modal);
    },
    handleTableTitleFilter: G.handleTableTitleFilter,
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const component = (
        <EditReport
          fields={fields}
          hasIssues={true}
          setReport={setUsedReport}
          usedReport={selectedReport}
          requestPending={requestPending}
          updateReportRequest={updateReportRequest}
          createReportRequest={createReportRequest}
          onReportSet={() => getItemListRequest(true)}
        />
      );

      const modal = G.getDefaultReportModal(component);

      openModal(modal);
    },
  }),
  withHandlers({
    handleClickEditIcon: ({ openFixedPopup, handleShowAudit }: Object) => (e: Object, entity: Object) => {
      openFixedPopup({
        position: 'right',
        el: e.currentTarget,
        content: <RowActions entity={entity} handleShowAudit={handleShowAudit} />,
      });
    },
  }),
  branch(
    ({ selectedReport, initialDataLoaded }: Object) => R.or(
      R.not(initialDataLoaded),
      G.isNilOrEmpty(selectedReport),
    ),
    renderNothing,
  ),
  pure,
);

const AddWorkOrderButton = ({ handleCreateWorkOrder }: Object) => (
  <MainActionButton
    ml={20}
    height={30}
    width={130}
    onClick={handleCreateWorkOrder}
  >
    {G.getAddTitle(['titles:work-order', 'Work Order'])}
  </MainActionButton>
);

const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    totalCount,
    selectItem,
    pagination,
    reportList,
    handleDelete,
    filterParams,
    selectedReport,
    tableMaxHeight,
    titleSortValues,
    tableTitleFilters,
    getItemListRequest,
    handleClickEditIcon,
    handleUpdateWorkOrder,
    handleTableTitleFilter,
    openedFromFleetProfile,
    changeWorkOrderStatusRequest,
  } = props;

  if (R.not(selectedReport)) return null;

  const actionButtons = [
    {
      getPermissions,
      iconName: 'pencil',
      enableIfEditable: true,
      action: handleUpdateWorkOrder,
    },
    {
      getPermissions,
      iconName: 'trash',
      action: handleDelete,
      enableIfEditable: true,
    },
  ];

  const tableSettingsToUse = G.isTrue(openedFromFleetProfile)
    ? R.assoc('maxHeight', tableMaxHeight, tableSettings)
    : G.getTableSettingsWithMaxHeightByConditions({
      reportList,
      filterParams,
      tableSettings,
      selectedReport,
    });

  const elementActionsComponent = (entity: Object) => (
    <AuthWrapper has={getPermissions(entity, true)}>
      <IconWrapper px={12} cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, entity)}>
        {I.threeDots()}
      </IconWrapper>
    </AuthWrapper>
  );

  const data = {
    loading,
    itemList,
    totalCount,
    pagination,
    actionButtons,
    columnSettings,
    titleSortValues,
    tableTitleFilters,
    handleTableTitleFilter,
    report: selectedReport,
    onOptionClick: selectItem,
    useSearchableColumns: true,
    withResizableColumns: true,
    useNewTitleFilterInputs: true,
    tableSettings: tableSettingsToUse,
    renderRightStickedComponent: elementActionsComponent,
    callbackData: { openedFromFleetProfile, changeWorkOrderStatusRequest },
    filterProps: R.indexBy(R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(FILTER_PARAMS)),
    handleLoadMoreEntities: G.ifElse(
      loading,
      () => {},
      () => getItemListRequest(),
    ),
  };

  return <Table {...data} />;
};

const WorkOrderList = (props: Object) => {
  const {
    pl,
    height,
    minHeight,
    maxHeight,
    fleetType,
    primaryObjectGuid,
    withoutPageActions,
    primaryObjectUnitId,
    handleCreateWorkOrder,
    openedFromFleetProfile,
  } = props;

  const additionalComponent = G.isTrue(openedFromFleetProfile)
    ? (
      <AddWorkOrderButton
        handleCreateWorkOrder={() => handleCreateWorkOrder(fleetType, primaryObjectGuid, primaryObjectUnitId)}
      />
    ) : null;

  return (
    <ListWrapper
      p={15}
      pl={pl}
      height={height}
      minHeight={minHeight}
      maxHeight={maxHeight}
    >
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          {...props}
          height='auto'
          withCount={true}
          noExportable={true}
          popperWithCount={true}
          usePortalForFilters={true}
          type={GC.WORK_ORDER_REPORT}
          filterProps={FILTER_PARAMS}
          hiddenRightFilterInfo={false}
          additionalComponent={additionalComponent}
          title={G.getWindowLocale('titles:work-orders', 'Work Orders')}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex={1}>
        {renderTable(props)}
      </ZOrderWrapper>
      {
        R.not(withoutPageActions) &&
        <PageActions
          mainActions={[
            {
              permissions: [PC.FLEET_TRUCK_WRITE],
              action: () => handleCreateWorkOrder(GC.FIELD_TRUCK),
              text: G.getAddTitle(['titles:truck-work-order', 'Truck Work Order']),
              styles: {
                textColor: G.getTheme('colors.light.darkBlue'),
                bgColor: G.getTheme('colors.light.lightBlueGrey'),
              },
            },
            {
              permissions: [PC.FLEET_TRAILER_WRITE],
              action: () => handleCreateWorkOrder(GC.FIELD_TRAILER),
              text: G.getAddTitle(['titles:trailer-work-order', 'Trailer Work Order']),
            },
          ]}
        />
      }
    </ListWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  requestPending: makeSelectReportStatus(state),
  reportList: makeSelectAvailableReports(state),
  titleSortValues: makeSelectTitleSortValues(state),
  currentBranchGuid: makeSelectCurrentBranchGuid(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

const enhanceWithConnect = compose(
  connect(mapStateToProps, {
    setReports,
    setUsedReport,
    cleanQuickFilter,
    deleteItemRequest,
    setTableTitleSort,
    getItemListRequest,
    createReportRequest,
    updateReportRequest,
    setTableTitleFilter,
    setQuickFilterParams,
    resetListAndPagination,
    changeDefaultReportRequest,
    changeWorkOrderStatusRequest,
    createOrUpdateWorkOrderRequest,
  }),
  enhance,
);

export const Component = enhanceWithConnect(WorkOrderList);

export default enhanceWithConnect(WorkOrderList);

import React from 'react';
import * as R from 'ramda';
// components
import TextComponent from '../../../components/text';
import { ActionBox } from '../../../components/action-box';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { priorityTypeOptions, workOrderStatusOptions, fleetServiceIssueStatusOptions } from '../../../helpers/options';
// report-common
import { getFilterParamsFromColumnSettings } from '../../../report-common';
// utilities
import routesMap from '../../../utilities/routes';
// feature work-order
import { isSingleCurrency } from '../helpers';
//////////////////////////////////////////////////

const tableSettings = {
  minHeight: 320,
  cellFontSize: 13,
  titleFontSize: 14,
  tableRowHeight: 40,
  titleRowHeight: 45,
  allowEditBtn: true,
  checkBoxCellWidth: 60,
  searchableTitles: true,
  allowSelectItems: false,
  maxHeight: 'calc(100vh - 145px)',
  checkBoxCellJustifyContent: 'unset',
};

const UnitIdCell = ({ guid, route, unitId, permissions, openedFromFleetProfile }: Object) => {
  if (G.isNilOrEmpty(unitId)) return null;

  if (R.or(G.isTrue(openedFromFleetProfile), G.hasNotAmousCurrentUserPermissions(permissions))) {
    return (
      <TextComponent maxWidth='90%' display='block' title={unitId} withEllipsis={true}>
        {unitId}
      </TextComponent>
    );
  }

  const action = () => G.goToRoute(G.getPropFromObject(route, routesMap)(guid));

  return <ActionBox text={unitId} action={action} />;
};

const columnSettings = {
  [GC.FIELD_NAME]: {
    width: 180,
    name: 'titles:name',
  },
  [GC.FIELD_STATUS]: {
    width: 180,
    name: 'titles:status',
    type: 'selectDropdown',
    selectOptions: workOrderStatusOptions,
    selectPermissions: (data: Object) => G.ifElse(
      G.isNotNilAndNotEmpty(G.getPropFromObject(GC.GRC.FLEET_TRUCK_GUID, data)),
      [PC.FLEET_TRUCK_WRITE],
      [PC.FLEET_TRAILER_WRITE],
    ),
    selectAction: (status: string, data: Object, { changeWorkOrderStatusRequest }: Object) =>
      changeWorkOrderStatusRequest({
        values: { status, [GC.FIELD_GUID]: G.getGuidFromObject(data) },
        entityType: G.ifElse(
          G.isNotNilAndNotEmpty(G.getPropFromObject(GC.GRC.FLEET_TRUCK_GUID, data)),
          GC.FIELD_TRUCK,
          GC.FIELD_TRAILER,
        ),
      }),
    filter: {
      type: 'selectMultiple',
      options: workOrderStatusOptions,
      placeholder: { text: 'Choose', key: 'titles:choose' },
    },
  },
  [GC.GRC.FLEET_TRUCK_UNIT_ID]: {
    width: 200,
    name: ['titles:truck', 'titles:unit-id'],
    customComponent: ({ data }: Object) => (
      <UnitIdCell
        route='fleetTruckProfile'
        permissions={[PC.FLEET_TRUCK_WRITE]}
        guid={G.getPropFromObject(GC.GRC.FLEET_TRUCK_GUID, data)}
        unitId={G.getPropFromObject(GC.GRC.FLEET_TRUCK_UNIT_ID, data)}
      />
    ),
  },
  [GC.GRC.FLEET_TRAILER_UNIT_ID]: {
    width: 200,
    name: ['titles:trailer', 'titles:unit-id'],
    customComponent: ({ data, callbackData: { openedFromFleetProfile } }: Object) => (
      <UnitIdCell
        route='fleetTrailerProfile'
        permissions={[PC.FLEET_TRAILER_WRITE]}
        openedFromFleetProfile={openedFromFleetProfile}
        guid={G.getPropFromObject(GC.GRC.FLEET_TRAILER_GUID, data)}
        unitId={G.getPropFromObject(GC.GRC.FLEET_TRAILER_UNIT_ID, data)}
      />
    ),
  },
  [GC.FIELD_DUE_DATE]: {
    type: 'date',
    name: 'titles:due-date',
    filter: {
      type: 'date',
    },
  },
  [GC.FIELD_EXPECTED_COMPLETED_DATE]: {
    type: 'date',
    name: 'titles:expected-completed-date',
    filter: {
      type: 'date',
    },
  },
  [GC.FIELD_ACTUAL_COMPLETED_DATE]: {
    type: 'date',
    name: 'titles:actual-completed-date',
    filter: {
      type: 'date',
    },
  },
  [`${GC.SYSTEM_LIST_INVOICES}.${GC.FIELD_NORMALIZED_TOTAL}`]: {
    sortable: false,
    searchable: false,
    name: ['titles:invoices', 'titles:normalized-total'],
    filter: { type: 'string', collection: true, disableSort: true, disableFilter: true },
    customComponent: ({ data }: Object) => {
      const normalizedTotals = G.getPropFromObject(`${GC.SYSTEM_LIST_INVOICES}.${GC.FIELD_NORMALIZED_TOTAL}`, data);

      if (R.or(G.isNilOrEmpty(normalizedTotals), R.not(isSingleCurrency(normalizedTotals)))) return null;

      const normalizedTotal = G.mathRoundNumber(R.reduce(
        (acc: number, normalizedTotal: Object) => R.add(acc, R.pathOr(0, [GC.FIELD_TOTAL], normalizedTotal)),
        0,
        normalizedTotals,
      ));

      const normalizedCurrencySymbol = G.getCurrencySymbol(
        R.path([0, GC.FIELD_CURRENCY], normalizedTotals),
      );

      const title = `${normalizedCurrencySymbol} ${normalizedTotal}`;

      return (
        <TextComponent
          p='4px 6px'
          title={title}
          maxWidth='100%'
          display='block'
          fontWeight={700}
          borderRadius='3px'
          withEllipsis={true}
          width='fit-content'
          bg={G.getTheme('colors.light.blue')}
          color={G.getTheme('colors.light.mainLight')}
        >
          {title}
        </TextComponent>
      );
    },
  },
  [`${GC.FIELD_SERVICE_VENDOR}.${GC.FIELD_NAME}`]: {
    name: ['titles:service-vendor', 'titles:name'],
  },
  [`${GC.FIELD_ASSIGNEE}.${GC.FIELD_FIRST_NAME}`]: {
    name: ['titles:assignee', 'titles:first-name'],
  },
  [`${GC.FIELD_ASSIGNEE}.${GC.FIELD_LAST_NAME}`]: {
    name: ['titles:assignee', 'titles:last-name'],
  },
  [`${GC.FIELD_ASSIGNEE}.${GC.FIELD_USER_LOGIN_ID}`]: {
    name: ['titles:assignee', 'titles:login-id'],
  },
  [GC.GRC.FLEET_SERVICE_ISSUES_ID]: {
    name: ['titles:issues', 'titles:issue-id'],
    filter: {
      type: 'string',
      collection: true,
    },
  },
  [GC.GRC.FLEET_SERVICE_ISSUES_SUMMARY]: {
    name: ['titles:issues', 'titles:summary'],
    filter: {
      type: 'string',
      collection: true,
    },
  },
  [GC.GRC.FLEET_SERVICE_ISSUES_STATUS]: {
    type: 'collectionOfEnums',
    name: ['titles:issues', 'titles:status'],
    filter: {
      collection: true,
      type: 'selectMultiple',
      options: fleetServiceIssueStatusOptions,
    },
  },
  [GC.GRC.FLEET_SERVICE_ISSUES_PRIORITY]: {
    type: 'collectionOfEnums',
    name: ['titles:issues', 'titles:priority'],
    filter: {
      collection: true,
      type: 'selectMultiple',
      options: priorityTypeOptions,
    },
  },
  [GC.GRC.FLEET_SERVICE_ISSUES_DESCRIPTION]: {
    name: ['titles:issues', 'titles:description'],
    filter: {
      type: 'string',
      collection: true,
    },
  },
  [GC.FIELD_CREATED_BY]: {
    width: 180,
    name: 'titles:created-by',
  },
  [GC.FIELD_CREATED_DATE]: {
    type: 'date',
    name: 'titles:created-date',
    filter: {
      type: 'date',
    },
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 180,
    name: 'titles:updated-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    type: 'date',
    name: 'titles:updated-date',
    filter: {
      type: 'date',
    },
  },
};

const FILTER_PARAMS = getFilterParamsFromColumnSettings(columnSettings);

export {
  tableSettings,
  FILTER_PARAMS,
  columnSettings,
};

import * as R from 'ramda';
import { put } from 'redux-saga/effects';
// feature sockets-v2
import * as A from '../actions';
// import * as LC from '../constants';
import * as C from '../constants';
//////////////////////////////////////////////////

const LBT = R.prop('loadBoardMessageType', C);

function* handleLoadBoardMessageReceived(actionPart: string, data: Object) {
  switch (actionPart) {
    case LBT.lbResult:
      yield put(A.socketLBResultReceived(data));
      break;
    case LBT.filterState:
      yield put(A.socketFilterStateReceived(data));
      break;
    case LBT.lbLogin:
      yield put(A.socketLBStateReceived(data));
      break;
    case LBT.lbConfig:
      yield put(A.socketLBConfigStateReceived(data));
      break;
    case LBT.posted:
      yield put(A.socketPostedShipmentStateReceived(data));
      break;
    case LBT.committed:
      yield put(A.socketBookedShipmentStateReceived(data));
      break;
    default:
  }
}

export default handleLoadBoardMessageReceived;

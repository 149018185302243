import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
//////////////////////////////////////////////////

const getRowTotal = (cost: number, quantity: number) => G.NaNToZero(G.mathRoundNumber(R.multiply(cost, quantity)));

const getSubTotal = (costs: Array) => R.reduce((acc: number, item: Object) => {
  const rowTotal = getRowTotal(
    R.pathOr(0, [GC.FIELD_COST], item),
    R.pathOr(1, [GC.FIELD_QUANTITY], item),
  );

  return R.add(acc, rowTotal);
}, 0, costs);

const getCostsTotal = ({ fees, parts, labors }: Array) =>
  R.sum([getSubTotal(fees), getSubTotal(parts), getSubTotal(labors)]);

const isSingleCurrency = (costs: Array) => R.compose(
  R.equals(1),
  R.length,
  R.uniqBy(R.prop(GC.FIELD_CURRENCY)),
)(costs);

export {
  getRowTotal,
  getSubTotal,
  getCostsTotal,
  isSingleCurrency,
};

import { createAction } from 'redux-act';
// report-common
import { getReportActions } from '../../report-common';
//////////////////////////////////////////////////

const {
  setReports,
  setReportType,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemRequest,
  deleteItemSuccess,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
} = getReportActions();

const setFleetProfileSettings = createAction('setFleetProfileSettings');
const visitWorkOrdersOnFleetPage = createAction('visitWorkOrdersOnFleetPage');
const getWorkOrderInvoicesRequest = createAction('getWorkOrderInvoicesRequest');
const changeWorkOrderStatusRequest = createAction('changeWorkOrderStatusRequest');
const deleteWorkOrderInvoiceRequest = createAction('deleteWorkOrderInvoiceRequest');
const createOrUpdateWorkOrderRequest = createAction('createOrUpdateWorkOrderRequest');
const createOrUpdateWorkOrderInvoiceRequest = createAction('createOrUpdateWorkOrderInvoiceRequest');

export {
  // report
  setReports,
  setReportType,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemRequest,
  deleteItemSuccess,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
  // report
  setFleetProfileSettings,
  visitWorkOrdersOnFleetPage,
  getWorkOrderInvoicesRequest,
  changeWorkOrderStatusRequest,
  deleteWorkOrderInvoiceRequest,
  createOrUpdateWorkOrderRequest,
  createOrUpdateWorkOrderInvoiceRequest,
};

import { createAction } from 'redux-act';
//////////////////////////////////////////////////

// Socket
export const socketConnectRequest = createAction('socketConnectRequest');
export const tryToReconnectSocket = createAction('tryToReconnectSocket');
export const socketDisconnectRequest = createAction('socketDisconnectRequest');
export const socketConnectionSuccess = createAction('socketConnectionSuccess');

// LOAD BOARD MESSAGES
export const socketLBStateReceived = createAction('socketLBStateReceived');
export const socketLBResultReceived = createAction('socketLBResultReceived');
export const socketFilterStateReceived = createAction('socketFilterStateReceived');
export const socketLBConfigStateReceived = createAction('socketLBConfigStateReceived');
export const socketPostedShipmentStateReceived = createAction('socketPostedShipmentStateReceived');
export const socketBookedShipmentStateReceived = createAction('socketBookedShipmentStateReceived');

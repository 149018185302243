import React from 'react';
// components
import TextComponent from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box } from '../../../ui';
//////////////////////////////////////////////////

export const TotalComponent = ({ ml, mr, maxWidth, totalText }: Object) => (
  <Box ml={ml} mr={mr} p='5px' borderRadius='5px' bg='titleDarkBlue' color={G.getTheme('colors.white')}>
    <TextComponent display='block' title={totalText} withEllipsis={true} maxWidth={maxWidth}>
      {totalText}
    </TextComponent>
  </Box>
);

import * as R from 'ramda';
import FileSaver from 'file-saver';
import { put, all, call, delay, select, takeLatest } from 'redux-saga/effects';
import { updateReportFromReportListSuccess } from '../../common/actions';
// components
import { openLoader, closeLoader } from '../../components/loader/actions';
import {
  checkReportFunction,
  transformSearchCriteriaBeforeReportPost,
} from '../../components/edit-report/helpers';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { getVendorCompensationListRequest } from '../fleet/vendor/sagas';
import { handleGetDriverCompensationsSaga } from '../fleet/driver/sagas';
import { handleGetItemListSaga as handleGetUsersListSaga } from '../user/sagas';
import { handleGetItemListSaga as handleGetBranchListSaga } from '../branch/sagas';
import { getReferenceTypesListRequestSaga, getAllAvailableRefTypesByScopeRequestSaga } from '../reference/sagas';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { getMockReport, generateDefaultReport } from '../../report-common';
// sagas
import { visitPageSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature report-format
import * as A from './actions';
import { FILTER_PARAMS } from './settings/filter-props';
import { exportEndpointsMap } from './settings/export-endpoints-map';
import {
  makeSelectIsEditPage,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectQuickFilteredParams,
} from './selectors';
//////////////////////////////////////////////////

export function* handleListOfReportsRequestSaga() {
  try {
    yield put(A.setListLoading(true));
    const pagination = yield select(makeSelectPagination());
    const reportParams = yield select(makeSelectUsedReport());
    const filterParams = yield select(makeSelectQuickFilteredParams());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const requiredFields = [
      { name: GC.FIELD_OWNER, freezed: false, sequence: 100, reference: false },
      { name: GC.FIELD_TYPE, freezed: false, sequence: 101, reference: false },
    ];
    let fields = G.getOrElse(reportParams, 'fields', []);
    requiredFields.forEach((field: Object) => {
      if (R.not(R.any(R.propEq(field.name, 'name'), fields))) {
        fields = R.concat(
          fields,
          R.of(Array, field),
        );
      }
    });
    const reqBody = {
      fields,
      limit: pagination.limit,
      offset: pagination.offset,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      orderFields: G.getOrElse(reportParams, 'orderFields', []),
      searchCriteria: transformSearchCriteriaBeforeReportPost(G.getOrElse(reportParams, 'searchCriteria', [])),
    };
    const options = {
      data: R.or(G.setSearchCriteria({ filterParams, reqBody }), reqBody),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.listReports, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedListOfReportsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleListOfReportsRequestSaga fail');
    }
    yield put(A.setListLoading(false));
  } catch (error) {
    yield put(A.setListLoading(false));
    yield call(G.handleException, error, 'handleListOfReportsRequestSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

const pageSagasMap = {
  [GC.USER_REPORT]: handleGetUsersListSaga,
  [GC.BRANCH_REPORT]: handleGetBranchListSaga,
  [GC.REPORT_REPORT]: handleListOfReportsRequestSaga,
  [GC.REFERENCE_REPORT]: getReferenceTypesListRequestSaga,
  [GC.DRIVER_COMPENSATION_REPORT]: handleGetDriverCompensationsSaga,
  [GC.VENDOR_COMPENSATION_REPORT]: getVendorCompensationListRequest,
};

function* handleQuickFilteredListRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    yield call(pageSagasMap[payload.type], { payload });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleQuickFilteredListRequestSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

const scopesByReportTypeMap = {
  [GC.CLO_REPORT]: [GC.REF_SCOPE_NAME_CLO],
  [GC.TEL_REPORT]: [GC.REF_SCOPE_NAME_TEL],
  [GC.USER_REPORT]: [GC.REF_SCOPE_NAME_USER],
  [GC.ITEM_REPORT]: [GC.REF_SCOPE_NAME_ITEM],
  [GC.BRANCH_REPORT]: [GC.REF_SCOPE_NAME_BRANCH],
  [GC.CARRIER_REPORT]: [GC.REF_SCOPE_NAME_CARRIER],
  [GC.FLEET_TRUCK_REPORT]: [GC.REF_SCOPE_NAME_FLEET_TRUCK],
  [GC.FLEET_DRIVER_REPORT]: [GC.REF_SCOPE_NAME_FLEET_DRIVER],
  [GC.FLEET_VENDOR_REPORT]: [GC.REF_SCOPE_NAME_FLEET_VENDOR],
  [GC.LOCATION_TEMPLATE_REPORT]: [GC.REF_SCOPE_NAME_LOCATION],
  [GC.FLEET_TRAILER_REPORT]: [GC.REF_SCOPE_NAME_FLEET_TRAILER],
  [GC.CARRIER_INVOICE_REPORT]: [GC.REF_SCOPE_NAME_CARRIER_INVOICE],
  [GC.ROUTE_REPORT]: [GC.REF_SCOPE_NAME_TEL, GC.REF_SCOPE_NAME_CLO],
  [GC.ROUTE_BY_LOAD_REPORT]: [GC.REF_SCOPE_NAME_TEL, GC.REF_SCOPE_NAME_CLO],
  [GC.CLO_EVENT_REPORT]: [GC.REF_SCOPE_NAME_LOAD_EVENT, GC.REF_SCOPE_NAME_CLO],
  [GC.VENDOR_INVOICE_REPORT]: [GC.REF_SCOPE_NAME_FLEET_INVOICE, GC.REF_SCOPE_NAME_TEL],
  [GC.CUSTOMER_INVOICE_REPORT]: [GC.REF_SCOPE_NAME_CUSTOMER_INVOICE, GC.REF_SCOPE_NAME_CLO],
  [GC.DRIVER_PAYROLL_INVOICE_REPORT]: [GC.REF_SCOPE_NAME_FLEET_INVOICE, GC.REF_SCOPE_NAME_TEL],
};

export function* handleReportRequestSaga({ guid, modal }: Object) {
  try {
    let res;
    const isEditPage = yield select(makeSelectIsEditPage());
    if (guid.includes('Default')) {
      res = G.getMockResponse(200, getMockReport(guid));
    } else {
      res = yield call(sendRequest, 'get', endpointsMap.getCurrentReportEndpoint(guid));
    }
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const scopes = R.path([data.type], scopesByReportTypeMap);

      if (G.isNotNil(scopes)) {
        yield all(scopes.map((scope: string) => call(getAllAvailableRefTypesByScopeRequestSaga, { payload: scope })))
      }

      const usedReport = checkReportFunction(R.of(Array, data));

      if (R.not(isEditPage)) {
        yield put(A.receivedUsedReportSuccess(...usedReport));
      }

      if (G.isFunction(modal)) yield put(A.openEditReportModal({ guid, modal, usedReport }));
    } else {
      yield call(G.handleFailResponse, res, 'handleReportRequestSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleReportRequestSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleUpdateReportOwnerRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(sendRequest, 'put', endpointsMap.changeOwnerReport, { data: payload });
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.resetListAndPagination());
      yield call(handleListOfReportsRequestSaga, { payload: true });
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateReportOwnerRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleUpdateReportOwnerRequestSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleAvailableReportRequestSaga({ payload, notSetUsedReport }: Object) {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    if (G.isNilOrEmpty(currentBranchGuid)) return false;
    const reportType = payload.reportType;
    const params = {
      reportType,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };
    const res = yield call(sendRequest, 'get', endpointsMap.listReports, { params });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const reports = checkReportFunction(data);
      yield put(A.receivedAvailableReportsSuccess(reports));
      const defaultReport = G.findDefaultReport(reports);
      const usedReport = R.or(defaultReport, generateDefaultReport(GC.REPORT_REPORT));

      if (R.not(notSetUsedReport)) {
        yield put(A.setUsedReport(usedReport));
      }

      yield call(pageSagasMap[reportType], { payload });
    } else {
      yield call(G.handleFailResponse, res, 'handleAvailableReportRequestSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleAvailableReportRequestSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

export function* handleUpdateReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const isEditPage = yield select(makeSelectIsEditPage());

    const searchCriteria = transformSearchCriteriaBeforeReportPost(R.path(['searchCriteria'], payload));
    const newPayload = R.set(R.lensProp('searchCriteria'), searchCriteria, payload);

    const endpoint = G.ifElse(
      R.propEq(GC.REPORT_VIEW_TYPE_PIVOT, 'viewType', payload),
      endpointsMap.reportPivot,
      endpointsMap.report,
    );

    const res = yield call(sendRequest, 'put', endpoint, { data: newPayload });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(updateReportFromReportListSuccess(data));

      yield put(A.resetListAndPagination());

      const userReport = yield select(makeSelectUsedReport());

      if (R.and(isEditPage, R.not(R.eqProps(GC.FIELD_GUID, userReport, data)))) {
        yield delay(100);

        yield put(closeLoader());
        yield put(A.getListOfReportsRequest());
      } else {
        // TODO: check why we reset searchCriteria
        yield put(A.setUsedReport(G.getReportSortedBySeqFreez(
          R.set(R.lensProp('searchCriteria'),
          R.path(['searchCriteria'], payload),
          data,
        ))));

        yield call(
          handleAvailableReportRequestSaga,
          {
            notSetUsedReport: true,
            payload: {
              reportType: GC.REPORT_REPORT,
              pathname: GC.ROUTE_PATH_REPORTS_LIST,
            },
          },
        );
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateReportRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateReportRequestSaga exception');
  }
}

export function* handleCreateReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const { type } = payload;

    const isEditPage = yield select(makeSelectIsEditPage());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const data = R.assoc(GC.FIELD_BRANCH_GUID, currentBranchGuid, payload);

    const endpoint = G.ifElse(
      R.propEq(GC.REPORT_VIEW_TYPE_PIVOT, 'viewType', payload),
      endpointsMap.reportPivot,
      endpointsMap.report,
    );

    const res = yield call(sendRequest, 'post', endpoint, { data });

    const { status } = res;

    yield put(A.resetListAndPagination());

    if (G.isResponseSuccess(status)) {
      if (R.or(isEditPage, G.notEquals(type, GC.REPORT_REPORT))) {
        yield delay(100);

        yield put(closeLoader());
        yield put(A.getListOfReportsRequest());
      } else {
        yield put(A.setUsedReport(G.getReportSortedBySeqFreez(res.data)));

        yield call(
          handleAvailableReportRequestSaga,
          {
            notSetUsedReport: true,
            payload: {
              reportType: GC.REPORT_REPORT,
              pathname: GC.ROUTE_PATH_REPORTS_LIST,
            },
          },
        );
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateReportRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateReportRequestSaga exception');
  }
}

export function* handleChangeDefaultReportSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const isEditPage = yield select(makeSelectIsEditPage());
    const res = yield call(sendRequest, 'put', endpointsMap.changeDefaultReport, { data: payload });
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      if (isEditPage) {
        yield put(closeLoader());
      } else {
        yield call(
          handleAvailableReportRequestSaga,
          {
            payload: {
              reportType: GC.REPORT_REPORT,
              pathname: GC.ROUTE_PATH_REPORTS_LIST,
            },
          },
        );
        yield call(handleListOfReportsRequestSaga, { payload: true });
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleChangeDefaultReportSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleChangeDefaultReportSaga exception');
  }
}


function* handleExportReportDataSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const filterParams = yield select(makeSelectQuickFilteredParams());
    const reqBody = {
      fields: payload.fields,
      orderFields: payload.orderFields,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      searchCriteria: payload.searchCriteria,
    };
    const options = {
      resType: 'arraybuffer',
      params: { format: payload.fileType },
      data: G.setSearchCriteria({ filterParams, reqBody }),
    };
    const listOrder = G.ifElse(
      payload.listType,
      `${payload.type}-${payload.listType}`,
      payload.type,
    );
    const res = yield call(sendRequest, 'post', exportEndpointsMap[listOrder], options);
    const { data, status, headers } = res;
    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      const file = new window.Blob(
        R.of(Array, data),
        { type: headers['content-type'] },
      );
      FileSaver.saveAs(file, `${payload.name}.xlsx`);
    } else {
      yield call(G.handleFailResponse, res, 'handleDownloadTruckSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleExportReportDataSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

export function* handleSetFilterParams() {
  yield put(A.setFilterProps(FILTER_PARAMS));
}

function* handleVisitReportsListPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_REPORTS_LIST_PAGE);
    yield put(openLoader({ showDimmer: true }));
    yield put(A.resetListAndPagination());
    yield put(A.setEditPage(false));
    yield put(A.receivedAvailableReportsSuccess([]));
    yield put(A.cleanQuickFilter());
    yield put(A.setIgnorePromptStatus(false));
    yield put(A.getAvailableReportsRequest(payload));
    yield call(handleSetFilterParams);
    yield put(closeLoader());
    break;
  }
}

function* handleDeleteReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const url = endpointsMap.getCurrentReportEndpoint(payload.guid);
    const res = yield call(sendRequest, 'delete', url);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      const usedReport = yield select(makeSelectUsedReport());
      if (R.equals(usedReport.guid, payload.guid)) {
        const payload = {
          reportType: GC.REPORT_REPORT,
          pathname: G.getCurrentPathname(),
        };
        yield call(handleVisitReportsListPageSaga, { payload });
      }
      yield put(A.receivedDeleteReportSuccess(payload.guid));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteReportRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleDeleteReportRequestSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleGetReportEditDataSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    const { guid, modal } = payload;
    yield put(openLoader({ showDimmer: true }));
    yield put(A.setEditPage(true));
    yield call(handleReportRequestSaga, { guid, modal });
    yield put(closeLoader());
    break;
  }
}

function* reportFormatWatcherSaga() {
  yield takeLatest(A.getReportDataRequest, handleGetReportEditDataSaga);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.deleteReportRequest, handleDeleteReportRequestSaga);
  yield takeLatest(A.exportReportDataRequest, handleExportReportDataSaga);
  yield takeLatest(A.getListOfReportsRequest, handleListOfReportsRequestSaga);
  yield takeLatest(GC.VISIT_REPORTS_LIST_PAGE, handleVisitReportsListPageSaga);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
  yield takeLatest(A.getAvailableReportsRequest, handleAvailableReportRequestSaga);
  yield takeLatest(A.updateReportOwnerRequest, handleUpdateReportOwnerRequestSaga);
  yield takeLatest(A.getQuickFilteredListRequest, handleQuickFilteredListRequestSaga);
}

export default reportFormatWatcherSaga;

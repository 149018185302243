import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// common
import { updateReportFromReportListSuccess } from '../../../common/actions';
// report-common
import { getReportReducers } from '../../../report-common';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature template-inspection
import * as A from './actions';
import * as H from './helpers';
import * as C from './constants';
import { defaultSection } from './settings/default-data';
//////////////////////////////////////////////////

const {
  setReports,
  selectItem,
  setListType,
  initialState,
  setUsedReport,
  setListLoading,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemSuccess,
  getItemListSuccess,
  setTableTitleFilter,
  setQuickFilterParams,
  resetListAndPagination,
  setInitialStateOmitReport,
  handleUpdateReportFromReportListSuccess,
} = getReportReducers();

const initialInspectionState = {
  ...initialState,
  activeSection: 0,
  currentInspectionTemplate: null,
  reportType: GC.INSPECTION_TEMPLATE,
};

const setCurrentInspectionTemplate = (state: Object, template: Object) => P.$set(
  'currentInspectionTemplate', template, state,
);

const updateTemplateFields = (state: Object, template: Object) => P.$set(
  'currentInspectionTemplate', template, state,
);

// section
const setActiveSection = (state: Object, activeSection: Object) => P.$set(
  'activeSection', activeSection, state,
);

const reorderSectionsInTemplate = (state: Object, { source, destination }: Object) => {
  const activeSection = P.$get('activeSection', state);
  const currentTemplate = P.$get('currentInspectionTemplate', state);
  const sections = R.prop('sections', currentTemplate);
  const reordered = H.reorderList(sections, R.prop('index', source), R.prop('index', destination));
  const activeSectionId = R.prop('id', R.prop(activeSection, sections));
  const newActiveIndex = R.findIndex(R.propEq(activeSectionId, 'id'), reordered);

  return P.$all(
    P.$set(
      'currentInspectionTemplate',
      {
        ...currentTemplate,
        sections: reordered,
      },
    ),
    P.$set('activeSection', newActiveIndex),
    state,
  );
};

const addSectionToTemplate = (state: Object, { component }: Object) => {
  const currentTemplate = P.$get('currentInspectionTemplate', state);
  const sections = R.prop('sections', currentTemplate);
  const id = R.length(sections);

  const section = {
    ...R.clone(defaultSection),
    type: component,
    id: G.generateGuid(),
    nameStatus: C.INSPECTION_NAME_STATUS_EDIT,
  };

  return P.$all(
    P.$set('activeSection', id),
    P.$add('currentInspectionTemplate.sections', section),
    state,
  );
};

const updateSectionFields = (state: Object, section: Object) => {
  const currentTemplate = P.$get('currentInspectionTemplate', state);
  const sections = R.prop('sections', currentTemplate);
  const id = R.prop('id', section);
  const index = R.findIndex(R.propEq(id, 'id'), sections);

  return P.$set(
    `currentInspectionTemplate.sections.${index}`,
    section,
    state,
  );
};

const deleteSectionFromTemplate = (state: Object, section: Object) => {
  const activeSection = P.$get('activeSection', state);
  const currentTemplate = P.$get('currentInspectionTemplate', state);
  const sections = R.prop('sections', currentTemplate);
  const id = R.prop('id', section);
  const index = R.findIndex(R.propEq(id, 'id'), sections);

  let updatedActiveSection = null;

  if (R.gt(R.length(sections), 1)) {
    if (R.equals(activeSection, index)) {
      updatedActiveSection = 0;
    } else if (R.gt(activeSection, index)) {
      updatedActiveSection = R.dec(activeSection);
    } else {
      updatedActiveSection = activeSection;
    }
  }

  return P.$all(
    P.$drop(`currentInspectionTemplate.sections.${index}`),
    P.$set('activeSection', updatedActiveSection),
    state,
  );
};

// component
const addComponentToActiveSection = (state: Object, component: Object) => {
  const activeSection = P.$get('activeSection', state);
  const currentTemplate = P.$get('currentInspectionTemplate', state);
  const sections = R.prop('sections', currentTemplate);
  const section = R.prop(activeSection, sections);
  const components = R.prop('components', section);
  const vehicleComponent = H.getActiveVehicleComponent(section);

  let nameFields;

  if (G.isNotNilAndNotEmpty(vehicleComponent)) {
    nameFields = {
      nameStatus: C.INSPECTION_NAME_STATUS_FILLED,
      name: R.prop(GC.FIELD_NAME, vehicleComponent),
      vehicleComponentType: R.propOr(null, 'activeVehicleComponentType', section),
    };
  } else {
    nameFields = {
      name: null,
      nameStatus: C.INSPECTION_NAME_STATUS_EDIT,
    };
  }

  components.push({
    ...H.getComponentWithOptionValues(R.clone(component)),
    ...nameFields,
    id: G.generateGuid(),
    sectionId: R.prop('id', section),
  });

  return P.$set(
    `currentInspectionTemplate.sections.${activeSection}`,
    {
      ...section,
      components,
      expanded: true,
    },
    state,
  );
};

const updateComponentFields = (state: Object, component: Object) => {
  const id = R.prop('id', component);
  const sectionId = R.prop('sectionId', component);
  const currentTemplate = P.$get('currentInspectionTemplate', state);
  const sections = R.prop('sections', currentTemplate);
  const sectionIndex = R.findIndex(R.propEq(sectionId, 'id'), sections);
  const section = R.prop(sectionIndex, sections);
  const components = R.prop('components', section);
  const componentIndex = R.findIndex(R.propEq(id, 'id'), components);

  return P.$set(
    `currentInspectionTemplate.sections.${sectionIndex}.components.${componentIndex}`,
    component,
    state,
  );
};

const deleteComponentFromSection = (state: Object, component: Object) => {
  const id = R.prop('id', component);
  const sectionId = R.prop('sectionId', component);
  const currentTemplate = P.$get('currentInspectionTemplate', state);
  const sections = R.prop('sections', currentTemplate);
  const sectionIndex = R.findIndex(R.propEq(sectionId, 'id'), sections);
  const section = R.prop(sectionIndex, sections);
  const components = R.prop('components', section);
  const componentIndex = R.findIndex(R.propEq(id, 'id'), components);

  return P.$drop(
    `currentInspectionTemplate.sections.${sectionIndex}.components.${componentIndex}`,
    state,
  );
};

// truck sections
const addVehicleSectionToTemplate = (state: Object, { component, componentProps }: Object) => {
  const currentTemplate = P.$get('currentInspectionTemplate', state);
  const sections = R.prop('sections', currentTemplate);
  const id = R.length(sections);

  const section = {
    ...R.clone(defaultSection),
    type: component,
    photoRequired: true,
    id: G.generateGuid(),
    name: R.prop('name', componentProps),
    nameStatus: C.INSPECTION_NAME_STATUS_FILLED,
    activeVehicleComponentType: R.prop('activeVehicleComponentType', componentProps),
  };

  return P.$all(
    P.$set('activeSection', id),
    P.$add('currentInspectionTemplate.sections', section),
    state,
  );
};

const setActiveVehicleSectionComponent = (state: Object, { section, activeVehicleComponentType } : Object) => {
  const currentTemplate = P.$get('currentInspectionTemplate', state);
  const sections = R.prop('sections', currentTemplate);
  const activeVehicleSection = R.indexOf(section, sections);

  const vehicleSection = {
    ...R.clone(section),
    activeVehicleComponentType,
  };

  return P.$set(`currentInspectionTemplate.sections.${activeVehicleSection}`, vehicleSection, state);
};

export default createReducer({
  // report common
  [A.setReports]: setReports,
  [A.selectItem]: selectItem,
  [A.setListType]: setListType,
  [A.setUsedReport]: setUsedReport,
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.setReportPending]: setReportPending,
  [A.cleanQuickFilter]: cleanQuickFilter,
  [A.setTableTitleSort]: setTableTitleSort,
  [A.deleteItemSuccess]: deleteItemSuccess,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.setTableTitleFilter]: setTableTitleFilter,
  [A.setQuickFilterParams]: setQuickFilterParams,
  [A.resetListAndPagination]: resetListAndPagination,
  [A.setInitialStateOmitReport]: setInitialStateOmitReport,
  [updateReportFromReportListSuccess]: handleUpdateReportFromReportListSuccess,
  // template
  [A.setActiveSection]: setActiveSection,
  [A.updateSectionFields]: updateSectionFields,
  [A.addSectionToTemplate]: addSectionToTemplate,
  [A.updateTemplateFields]: updateTemplateFields,
  [A.updateComponentFields]: updateComponentFields,
  [A.reorderSectionsInTemplate]: reorderSectionsInTemplate,
  [A.deleteSectionFromTemplate]: deleteSectionFromTemplate,
  [A.deleteComponentFromSection]: deleteComponentFromSection,
  [A.addVehicleSectionToTemplate]: addVehicleSectionToTemplate,
  [A.addComponentToActiveSection]: addComponentToActiveSection,
  [A.setCurrentInspectionTemplate]: setCurrentInspectionTemplate,
  [A.setActiveVehicleSectionComponent]: setActiveVehicleSectionComponent,
}, initialInspectionState);

import React from 'react';
import * as Yup from 'yup';
import * as R from 'ramda';
// components
import TextComponent from '../../../components/text';
import { SelectFleetEntity } from '../../../components/select-fleet-entity';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Flex } from '../../../ui';
//////////////////////////////////////////////////

const workOrdervalidationSchema = ({ isTruck }: Object) => Yup.object().shape({
  [GC.FIELD_NAME]: G.yupStringRequired,
  [G.ifElse(isTruck, GC.FIELD_TRUCK_GUID, GC.FIELD_TRAILER_GUID)]: G.yupStringRequired,
});

const workOrderDefaultValues = {
  [GC.FIELD_NAME]: '',
  [GC.FIELD_DUE_DATE]: '',
  [GC.FIELD_TRUCK_GUID]: '',
  [GC.FIELD_TRAILER_GUID]: '',
  [GC.FIELD_ASSIGNEE_GUID]: '',
  [GC.FIELD_SERVICE_VENDOR_GUID]: '',
  [GC.FIELD_EXPECTED_COMPLETED_DATE]: '',
};

const inputWrapperStyles = {
  mb: 25,
  flexShrink: 1,
  flexBasis: 200,
};

const textStyles = { maxWidth: 140, withEllipsis: true };

const MultiValueLabel = ({ data }: Object) => {
  if (R.isNil(data)) return;

  const { label, status, priority } = data;

  const statusText = `${G.getWindowLocale('titles:status', 'Status')}: ${G.getEnumLocale(status)}`;

  const priorityText = G.isNilOrEmpty(priority)
    ? ''
    : `${G.getWindowLocale('titles:priority', 'Priority')}: ${G.getEnumLocale(priority)}`;

  return (
    <Flex p='5px 0px 5px 10px' flexDirection='column' alignItems='flex-start'>
      <TextComponent {...textStyles} title={label}>
        {label}
      </TextComponent>
      <TextComponent {...textStyles} title={statusText}>
        {statusText}
      </TextComponent>
      <TextComponent {...textStyles} title={priorityText}>
        {priorityText}
      </TextComponent>
    </Flex>
  );
};

const getWorkOrderFieldSettings = ({
  isEdit,
  isTruck,
  openedFromIssue,
  selectedFleetEntity,
  setSelectedFleetEntity,
  openedFromFleetProfile,
  handleGetServiceIssues,
}: Object) => [
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_NAME,
    label: ['titles:name', 'Name'],
  },
  {
    isRequired: true,
    inputWrapperStyles,
    type: 'customComponent',
    fieldName: G.ifElse(isTruck, GC.FIELD_TRUCK_GUID, GC.FIELD_TRAILER_GUID),
    label: G.ifElse(isTruck, ['titles:truck', 'Truck'], ['titles:trailer', 'Trailer']),
    Component: ({ name, value, hasError, setFieldValue }: Object) => (
      <SelectFleetEntity
        value={value}
        fieldName={name}
        isTruck={isTruck}
        isClearable={true}
        hasError={hasError}
        selectedFleetEntity={selectedFleetEntity}
        setSelectedFleetEntity={setSelectedFleetEntity}
        isDisabled={G.isAnyTrue(isEdit, openedFromIssue, openedFromFleetProfile)}
        handleSelect={(fieldName: string, value: any) => {
          setFieldValue(fieldName, value);
          handleGetServiceIssues(value);
        }}
      />
    ),
  },
  {
    inputWrapperStyles,
    type: 'reactSelect',
    useMenuPortalTarget: true,
    fieldName: GC.FIELD_SERVICE_VENDOR_GUID,
    options: 'serviceVendorListAvailabeOptions',
    label: ['titles:service-vendor', 'Service Vendor'],
  },
  {
    inputWrapperStyles,
    type: 'reactSelect',
    useMenuPortalTarget: true,
    options: 'assigneeOptions',
    fieldName: GC.FIELD_ASSIGNEE_GUID,
    label: ['titles:assigned-to', 'Assigned To'],
  },
  {
    isClearable: true,
    inputWrapperStyles,
    type: 'datePicker',
    fieldName: GC.FIELD_DUE_DATE,
    label: ['titles:due-date', 'Due Date'],
  },
  {
    isClearable: true,
    inputWrapperStyles,
    type: 'datePicker',
    fieldName: GC.FIELD_EXPECTED_COMPLETED_DATE,
    label: ['titles:expected-completed-date', 'Expected Completed Date'],
  },
  {
    isMulti: true,
    type: 'reactSelect',
    useMenuPortalTarget: true,
    options: 'serviceIssueOptions',
    fieldName: GC.FIELD_ISSUE_GUIDS,
    components: { MultiValueLabel },
    label: ['titles:issues', 'Issues'],
    inputWrapperStyles: { mb: 25, width: '100%' },
    disabled: (_: any, { truckGuid, trailerGuid }: Object) => G.isNilOrEmpty(G.ifElse(isTruck, truckGuid, trailerGuid)),
  },
];

export {
  workOrderDefaultValues,
  getWorkOrderFieldSettings,
  workOrdervalidationSchema,
};

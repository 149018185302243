// features
import { EXCEL_RESULTS_FILTER_PARAMS } from '../../rate-shop/settings';
import { getFilterParamsByReportType } from '../../fleet-list/settings';
import { FILTER_PARAMS as userParams } from '../../user/settings/filter-params';
import { ORDER_QUOTES_FILTER_PARAMS } from '../../quotes/settings/column-settings';
import { FILTER_PARAMS as branchFilterProps } from '../../branch/settings/filter-params';
import { FILTER_PARAMS as chartFilterParams } from '../../charts/settings/filter-params';
import { FILTER_PARAMS as ediParams } from '../../edi-integration/settings/filter-params';
import { FILTER_PARAMS as carrierFilterProps } from '../../carrier/settings/filter-params';
import { FILTER_PARAMS as iftaFilterProps } from '../../ifta-report/settings/filter-params';
import { cloChargeFilterParams, telChargeFilterParams } from '../../charge-report/settings';
import { FILTER_PARAMS as fuelCardFilterProps } from '../../fuel-cards/settings/filter-params';
import { FILTER_PARAMS as publicCloFilterProps } from '../../public-clo/settings/filter-params';
import { filterProps as inspectionTemplateFilterProps } from '../../template/inspection/helpers';
import { FILTER_PARAMS as tollChargeFilterProps } from '../../toll-charges/settings/filter-params';
import { FILTER_PARAMS as carrierEdiFilterParams } from '../../carrier-edi/settings/filter-params';
import { getFilterParams as getReferenceFilterProps } from '../../reference/settings/filter-params';
import { FILTER_PARAMS as inspectionFilterParams } from '../../inspections/settings/table-settings';
import { FILTER_PARAMS as masterInvoiceFilterProps } from '../../master-invoice/settings/filter-params';
import { FILTER_PARAMS as driverInvoiceFilterProps } from '../../invoice/driver/settings/filter-params';
import { FILTER_PARAMS as vendorInvoiceFilterProps } from '../../invoice/vendor/settings/filter-params';
import { getCLOFilterParams, getTELFilterParams } from '../../dispatch-board-new/settings/filter-params';
import { FILTER_PARAMS as serviceVendorFilterParams } from '../../service-vendor/settings/filter-params';
import { FILTER_PARAMS as fleetAccessorialFilterParams } from '../../fleet-profile/settings/accessorial';
import { FILTER_PARAMS as fleetCompensationFilterParams } from '../../fleet-profile/settings/compensation';
import { FILTER_PARAMS as carrierInvoiceFilterParams } from '../../invoice/carrier/settings/filter-params';
import { FILTER_PARAMS as customerInvoiceFilterProps } from '../../invoice/customer/settings/filter-params';
import { FILTER_PARAMS as losSubscriptionFilterProps } from '../../los-subscription/settings/filter-params';
import { searchResultsFilterParams as loadBoardFilterProps } from '../../load-board/settings/field-settings';
import { FILTER_PARAMS as routeByLoadFilterProps } from '../../dispatch-report/route/route-by-load/settings';
import { FILTER_PARAMS as carrierRateRequestFilterParams } from '../../carrier-rate-request-report/settings';
import { FILTER_PARAMS as payrollAccessorialFilterProps } from '../../fleet-profile/settings/payroll-accessorial';
import { loadFilterProps, routeFilterProps, orderFilterProps } from '../../dispatch-report/settings/filter-params';
import { FIELDS_AND_FILTER_PARAMS as carrierContractParams } from '../../contracts/carrier/settings/filter-params';
import { FIELDS_AND_FILTER_PARAMS as customerContractParams } from '../../contracts/customer/settings/filter-params';
import { filterProps as cloEventFilterProps } from '../../dispatch-planner-events/settings/clo-events-table-settings';
import { FILTER_PARAMS as serviceVendorInvoiceFilterProps } from '../../invoice/service-vendor/settings/filter-params';
import {
  getFilterParamsByReportTypeForEditReport as getTemplateFilterParamsByReportType,
} from '../../template/report/settings';
import {
  VENDOR_FILTER_PARAMS,
  FILTER_PARAMS as getDriverPayrollFilterProps,
} from '../../payroll/settings/filter-params';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const FILTER_PARAMS = [
  {
    type: 'string',
    value: GC.FIELD_BRANCH_DOT_BRANCH_NAME,
    name: G.getWindowLocale('titles:branch-name', 'Branch Name'),
  },
  {
    type: 'string',
    value: GC.FIELD_NAME,
    name: G.getWindowLocale('titles:name', 'Name'),
  },
  {
    type: 'string',
    value: GC.FIELD_TYPE,
    name: G.getWindowLocale('titles:type', 'Type'),
  },
  {
    type: 'string',
    value: GC.FIELD_OWNER,
    name: G.getWindowLocale('titles:owner', 'Owner'),
  },
  {
    type: 'boolean',
    value: GC.FIELD_PROMPT,
    name: G.getWindowLocale('titles:prompt', 'Prompt'),
  },
  {
    type: 'string',
    value: GC.FIELD_DESCRIPTION,
    name: G.getWindowLocale('titles:description', 'Description'),
  },
  {
    type: 'string',
    value: GC.FIELD_CREATED_BY,
    name: G.getWindowLocale('titles:created-by', 'Created By'),
  },
  {
    type: 'date',
    value: GC.FIELD_CREATED_DATE,
    name: G.getWindowLocale('titles:created-date', 'Created Date'),
  },
  {
    type: 'date',
    value: GC.FIELD_LAST_MODIFIED_DATE,
    name: G.getWindowLocale('titles:last-modified-date', 'Last Modified Date'),
  },
];

export const paramsByType = {
  [GC.EDI_REPORT]: ediParams,
  [GC.USER_REPORT]: userParams,
  [GC.TEL_REPORT]: loadFilterProps,
  [GC.REPORT_REPORT]: FILTER_PARAMS,
  [GC.CLO_REPORT]: orderFilterProps,
  [GC.IFTA_REPORT]: iftaFilterProps,
  [GC.ROUTE_REPORT]: routeFilterProps,
  [GC.CHART_REPORT]: chartFilterParams,
  [GC.BRANCH_REPORT]: branchFilterProps,
  [GC.PIVOT_TEL_REPORT]: loadFilterProps,
  [GC.PIVOT_CLO_REPORT]: orderFilterProps,
  [GC.CARRIER_REPORT]: carrierFilterProps,
  [GC.CLO_EVENT_REPORT]: cloEventFilterProps,
  [GC.FUEL_CARD_REPORT]: fuelCardFilterProps,
  [GC.ROUTE_CLO_REPORT]: getCLOFilterParams({}),
  [GC.ROUTE_TEL_REPORT]: getTELFilterParams({}),
  [GC.REFERENCE_REPORT]: getReferenceFilterProps,
  [GC.TOLL_CHARGE_REPORT]: tollChargeFilterProps,
  [GC.INSPECTION_REPORT]: inspectionFilterParams,
  [GC.ORDER_REQUEST_REPORT]: publicCloFilterProps,
  [GC.INVOICE_EDI_REPORT]: carrierEdiFilterParams,
  [GC.VENDOR_PAYROLL_REPORT]: VENDOR_FILTER_PARAMS,
  [GC.PAYROLL_REPORT]: getDriverPayrollFilterProps,
  [GC.CUSTOMER_PORTAL_CLO_REPORT]: orderFilterProps,
  [GC.ROUTE_BY_LOAD_REPORT]: routeByLoadFilterProps,
  [GC.CLO_RATE_CHARGE_REPORT]: cloChargeFilterParams,
  [GC.TEL_RATE_CHARGE_REPORT]: telChargeFilterParams,
  [GC.CARRIER_CONTRACT_REPORT]: carrierContractParams,
  [GC.ORDER_QUOTE_REPORT]: ORDER_QUOTES_FILTER_PARAMS,
  [GC.VENDOR_INVOICE_REPORT]: vendorInvoiceFilterProps,
  [GC.CUSTOMER_CONTRACT_REPORT]: customerContractParams,
  [GC.EXTERNAL_LOAD_BOARD_REPORT]: loadBoardFilterProps,
  [GC.SERVICE_VENDOR_REPORT]: serviceVendorFilterParams,
  [GC.CARRIER_INVOICE_REPORT]: carrierInvoiceFilterParams,
  [GC.CUSTOMER_INVOICE_REPORT]: customerInvoiceFilterProps,
  [GC.LOS_SUBSCRIPTION_REPORT]: losSubscriptionFilterProps,
  [GC.DRIVER_PAYROLL_INVOICE_REPORT]: driverInvoiceFilterProps,
  [GC.EXCEL_RATING_RESULT_REPORT]: EXCEL_RESULTS_FILTER_PARAMS,
  [GC.CUSTOMER_MASTER_INVOICE_REPORT]: masterInvoiceFilterProps,
  [GC.CARRIER_RATE_REQUEST_REPORT]: carrierRateRequestFilterParams,
  [GC.SERVICE_VENDOR_INVOICE_REPORT]: serviceVendorInvoiceFilterProps,
  [GC.INSPECTION_TEMPLATE]: Object.values(inspectionTemplateFilterProps),
  // fleet
  [GC.FLEET_DRIVER_COMPENSATION_REPORT]: fleetCompensationFilterParams,
  [GC.FLEET_VENDOR_COMPENSATION_REPORT]: fleetCompensationFilterParams,
  [GC.FLEET_TRUCK_REPORT]: getFilterParamsByReportType(GC.FLEET_TRUCK_REPORT),
  [GC.FLEET_VENDOR_REPORT]: getFilterParamsByReportType(GC.FLEET_VENDOR_REPORT),
  [GC.FLEET_DRIVER_REPORT]: getFilterParamsByReportType(GC.FLEET_DRIVER_REPORT),
  [GC.FLEET_TRAILER_REPORT]: getFilterParamsByReportType(GC.FLEET_TRAILER_REPORT),
  [GC.FLEET_VENDOR_ASSESSORIAL_REPORT]: fleetAccessorialFilterParams({
    disableAccessorialFilter: true,
  }),
  [GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT]: payrollAccessorialFilterProps({
    disableAccessorialFilter: true,
  }),
  [GC.FLEET_DRIVER_ASSESSORIAL_REPORT]: fleetAccessorialFilterParams({
    fleetType: GC.FIELD_DRIVER,
    disableAccessorialFilter: true,
  }),
  [GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT]: payrollAccessorialFilterProps({
    fleetType: GC.FIELD_VENDOR,
    disableAccessorialFilter: true,
  }),
  // template
  [GC.ITEM_REPORT]: getTemplateFilterParamsByReportType(GC.ITEM_REPORT),
  [GC.CONTACT_BOOK_REPORT]: getTemplateFilterParamsByReportType(GC.CONTACT_BOOK_REPORT),
  [GC.CLO_TEMPLATE_REPORT]: getTemplateFilterParamsByReportType(GC.CLO_TEMPLATE_REPORT),
  [GC.ROUTE_TEMPLATE_REPORT]: getTemplateFilterParamsByReportType(GC.ROUTE_TEMPLATE_REPORT),
  [GC.LOCATION_TEMPLATE_REPORT]: getTemplateFilterParamsByReportType(GC.LOCATION_TEMPLATE_REPORT),
  [GC.CONTAINER_TEMPLATE_REPORT]: getTemplateFilterParamsByReportType(GC.CONTAINER_TEMPLATE_REPORT),
  [GC.COMPENSATION_TEMPLATE_REPORT]: getTemplateFilterParamsByReportType(GC.COMPENSATION_TEMPLATE_REPORT),
};

export const reportTypeLocaleMap = {
  [GC.EDI_REPORT]: 'titles:edi',
  [GC.TEL_REPORT]: 'titles:tel',
  [GC.CLO_REPORT]: 'titles:clo',
  [GC.IFTA_REPORT]: 'titles:ifta',
  [GC.USER_REPORT]: 'titles:user',
  [GC.ROUTE_REPORT]: 'titles:route',
  [GC.CHART_REPORT]: 'titles:chart',
  [GC.BRANCH_REPORT]: 'titles:branch',
  [GC.CARRIER_REPORT]: 'titles:carrier',
  [GC.FUEL_CARD_REPORT]: 'titles:fuel-card',
  [GC.REFERENCE_REPORT]: 'titles:reference',
  [GC.PAYROLL_REPORT]: 'titles:driver-payroll',
  [GC.TOLL_CHARGE_REPORT]: 'titles:toll-charge',
  [GC.ORDER_REQUEST_REPORT]: 'titles:order-api',
  [GC.INVOICE_EDI_REPORT]: 'titles:carrier-edi',
  [GC.ORDER_QUOTE_REPORT]: 'titles:order-quote',
  [GC.ROUTE_BY_LOAD_REPORT]: 'titles:route-by-load',
  [GC.EXCEL_RATING_RESULT_REPORT]: 'titles:rate-shop',
  [GC.SERVICE_VENDOR_REPORT]: 'titles:service-vendor',
  [GC.VENDOR_INVOICE_REPORT]: 'titles:vendor-invoice',
  [GC.VENDOR_PAYROLL_REPORT]: 'titles:vendor-payroll',
  [GC.EXTERNAL_LOAD_BOARD_REPORT]: 'titles:load-board',
  [GC.CLO_RATE_CHARGE_REPORT]: 'titles:clo-rate-charge',
  [GC.TEL_RATE_CHARGE_REPORT]: 'titles:tel-rate-charge',
  [GC.CARRIER_INVOICE_REPORT]: 'titles:carrier-invoice',
  [GC.CARRIER_RATE_REQUEST_REPORT]: 'titles:bid-history',
  [GC.CARRIER_CONTRACT_REPORT]: 'titles:carrier-contract',
  [GC.CUSTOMER_INVOICE_REPORT]: 'titles:customer-invoice',
  [GC.CUSTOMER_PORTAL_CLO_REPORT]: 'titles:customer-portal',
  [GC.CUSTOMER_CONTRACT_REPORT]: 'titles:customer-contract',
  [GC.DRIVER_PAYROLL_INVOICE_REPORT]: 'titles:driver-invoice',
  [GC.CUSTOMER_MASTER_INVOICE_REPORT]: 'titles:master-invoice',
  [GC.SERVICE_VENDOR_INVOICE_REPORT]: 'titles:service-vendor-invoice',
  // fleet
  [GC.FLEET_TRUCK_REPORT]: 'titles:truck',
  [GC.FLEET_VENDOR_REPORT]: 'titles:vendor',
  [GC.FLEET_DRIVER_REPORT]: 'titles:driver',
  [GC.FLEET_TRAILER_REPORT]: 'titles:trailer',
  [GC.FLEET_VENDOR_ASSESSORIAL_REPORT]: 'titles:vendor-accessorial',
  [GC.FLEET_DRIVER_ASSESSORIAL_REPORT]: 'titles:driver-accessorial',
  [GC.FLEET_DRIVER_COMPENSATION_REPORT]: 'titles:driver-compensation',
  [GC.FLEET_VENDOR_COMPENSATION_REPORT]: 'titles:vendor-compensation',
  [GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT]: 'titles:driver-payroll-accessorial',
  [GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT]: 'titles:vendor-payroll-accessorial',
  // template
  [GC.ITEM_REPORT]: 'titles:item',
  [GC.CONTACT_BOOK_REPORT]: 'titles:contact-book',
  [GC.CLO_TEMPLATE_REPORT]: 'titles:clo-template',
  [GC.LOCATION_TEMPLATE_REPORT]: 'titles:location',
  [GC.CONTAINER_TEMPLATE_REPORT]: 'titles:container',
  [GC.ROUTE_TEMPLATE_REPORT]: 'titles:route-template',
  // TODO: uncomment if needed
  // [GC.CLO_EVENT_REPORT]: cloEventFilterProps,
  // [GC.ROUTE_CLO_REPORT]: getCLOFilterParams({}),
  // [GC.ROUTE_TEL_REPORT]: getTELFilterParams({}),
  // [GC.LOS_SUBSCRIPTION_REPORT]: losSubscriptionFilterProps,
};

import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  withProps,
  withHandlers,
  renderNothing,
} from 'react-recompose';
// components
import DimmerComponent from '../../components/loader/dimmer';
import { makeSelectLoader } from '../../components/loader/selectors';
import { openModal, closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
// features
import { EmailSendingQuote } from '../mail-sending';
import ErrorsComponent from '../new-do/components/errors';
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
import { makeSelectExpandedContainerOptions } from '../expanded-container/selectors';
import { openAddBranchModalRequest, getBranchConfigsByNamesRequest } from '../branch/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// ui
import { Box, Flex, PageWrapper } from '../../ui';
// feature new-do-quote
import Header from './components/header';
import Footer from './components/footer';
import LeftSection from './components/left-section';
import RightSection from './components/right-section';
import {
  cleanStore,
  setBranchInfo,
  setQuoteNumber,
  setDivisionGuid,
  setValueToStore,
  setActiveLeftTab,
  setExpirationDate,
  setFormDataToStop,
  addNewStopToStore,
  setActiveRightTab,
  setFormDataToStore,
  removeItemFromStore,
  removeStopFromStore,
  getBranchDataRequest,
  getBranchListRequest,
  sendDataToApiRequest,
  setDriverRateToStore,
  setCarrierRateToStore,
  removeValidationErrors,
  applyOrderRateUpliftRequest,
  recalculateLoadDistancesRequest,
  recalculateLoadDistancesSuccess,
} from './actions';
import {
  makeSelectRate,
  makeSelectStops,
  makeSelectStyling,
  makeSelectLoadType,
  makeSelectPageType,
  makeSelectDivision,
  makeSelectLoadTotals,
  makeSelectBranchList,
  makeSelectBranchInfo,
  makeSelectBranchGuid,
  makeSelectDriverRate,
  makeSelectQuoteNumber,
  makeSelectCarrierRate,
  makeSelectDivisionGuid,
  makeSelectMapLocations,
  makeSelectLeftActiveTad,
  makeSelectBranchConfigs,
  makeSelectExpirationDate,
  makeSelectRightActiveTad,
  makeSelectBranchRefTypes,
  makeSelectTripServiceDays,
  makeSelectValidationErrors,
  makeSelectReferenceFormData,
  makeSelectIntegrationCarriers,
} from './selectors';
//////////////////////////////////////////////////

const Body = (props: Object) => (
  <Flex
    p='15px 25px'
    flexWrap='wrap'
    alignItems='flex-start'
  >
    <LeftSection {...props} />
    <RightSection {...props} />
  </Flex>
);

const QuoteComponent = (props: Object) => {
  const { loader, validationErrors, removeValidationErrors } = props;

  return (
    <Box>
      {loader.showDimmer && <DimmerComponent tableCount={3} rectangleCount={4} />}
      <Box overflow='auto' height='calc(100vh - 60px)'>
        <Header {...props} />
        {
          validationErrors && (
          <ErrorsComponent
            type='error'
            errors={validationErrors}
            remove={removeValidationErrors}
            // TODO: check title with Mark ;)
            title='Oops! Looks like there are some mistakes, check the following:'
          />
          )
        }
        <Body {...props} isOrderQuote={true} />
      </Box>
      <Footer {...props} />
    </Box>
  );
};

const NewQuoteComponent = (props: Object) => (
  <PageWrapper pb='0' pl={R.pathOr('51', ['pageWrapperPaddingLeft'], props)} bgColor={G.getTheme('colors.bgGrey')}>
    <QuoteComponent {...props} />
  </PageWrapper>
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  rate: makeSelectRate(state),
  stops: makeSelectStops(state),
  loader: makeSelectLoader(state),
  styling: makeSelectStyling(state),
  loadType: makeSelectLoadType(state),
  pageType: makeSelectPageType(state),
  division: makeSelectDivision(state),
  branchGuid: makeSelectBranchGuid(state),
  loadTotals: makeSelectLoadTotals(state),
  branchList: makeSelectBranchList(state),
  branchInfo: makeSelectBranchInfo(state),
  driverRate: makeSelectDriverRate(state),
  carrierRate: makeSelectCarrierRate(state),
  quoteNumber: makeSelectQuoteNumber(state),
  divisionGuid: makeSelectDivisionGuid(state),
  mapLocations: makeSelectMapLocations(state),
  leftActiveTad: makeSelectLeftActiveTad(state),
  branchConfigs: makeSelectBranchConfigs(state),
  expirationDate: makeSelectExpirationDate(state),
  rightActiveTad: makeSelectRightActiveTad(state),
  branchRefTypes: makeSelectBranchRefTypes(state),
  tripServiceDays: makeSelectTripServiceDays(state),
  validationErrors: makeSelectValidationErrors(state),
  referenceFormData: makeSelectReferenceFormData(state),
  currentBranchGuid: makeSelectCurrentBranchGuid(state),
  integrationCarriers: makeSelectIntegrationCarriers(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
  expandedContainerOptions: makeSelectExpandedContainerOptions(state),
});

const enhance = compose(
  connect(mapStateToProps, {
    openModal,
    closeModal,
    openLoader,
    cleanStore,
    closeLoader,
    setBranchInfo,
    setQuoteNumber,
    setDivisionGuid,
    setValueToStore,
    setActiveLeftTab,
    setExpirationDate,
    setFormDataToStop,
    addNewStopToStore,
    setActiveRightTab,
    setFormDataToStore,
    removeStopFromStore,
    removeItemFromStore,
    getBranchListRequest,
    sendDataToApiRequest,
    getBranchDataRequest,
    setDriverRateToStore,
    setCarrierRateToStore,
    removeValidationErrors,
    openAddBranchModalRequest,
    applyOrderRateUpliftRequest,
    getBranchConfigsByNamesRequest,
    recalculateLoadDistancesRequest,
    recalculateLoadDistancesSuccess,
  }),
  withProps({ isOrderQuote: true }),
  withHandlers({
    handleClickSave: ({ sendDataToApiRequest }: Object) => () => sendDataToApiRequest({}),
    handleRateRequest: (props: Object) => () => {
      const { sendDataToApiRequest } = props;

      sendDataToApiRequest({ rateRequested: true });
    },
    handleSubmitAndEmail: (props: Object) => () => {
      const { openModal, branchGuid, sendDataToApiRequest } = props;

      const customHandleSubmit = (values: Object) => sendDataToApiRequest({
        sendEmail: true,
        sendToCustomerRequest: R.assoc(GC.FIELD_MAIL_TO, R.path([GC.FIELD_TO], values), values),
      });

      const modal = {
        component: (
          <EmailSendingQuote
            isMulti={false}
            branchGuid={branchGuid}
            customHandleSubmit={customHandleSubmit}
            hiddenFields={[GC.FIELD_MAIL_SENDING_ATTACHED_DOCUMENT_GUIDS]}
          />
        ),
        options: {
          height: 'auto',
          boxShadow: 'none',
          width: 'max-content',
          title: G.getWindowLocale('titles:new-message', 'New Message'),
        },
        p: '0px',
      };

      openModal(modal);
    },
    handleClickCancel: (props: Object) => () => {
      const { closeExpandedContainer, expandedContainerOptions } = props;

      if (R.pathEq(true, ['opened'], expandedContainerOptions)) {
        G.callFunctionWithArgs(closeExpandedContainer, true);
      }

      G.goToRoute(GC.ROUTE_PATH_ORDER_QUOTES);
    },
    handleChangeSearchInput: (props: Object) => (select: any) => {
      const { cleanStore, getBranchDataRequest } = props;

      if (R.isNil(select)) {
        return cleanStore();
      }

      const { value, label } = select;
      const branchGuid = value;
      cleanStore({
        [GC.FIELD_GUID]: branchGuid,
        [GC.FIELD_BRANCH_NAME]: label,
      });
      getBranchDataRequest(branchGuid);
    },
    handleChangeQuoteNumberInput: ({ setQuoteNumber }: Object) => (value: string) => (
      setQuoteNumber(value)
    ),
  }),
  branch(
    (props: Object) => R.not(props.initialDataLoaded),
    renderNothing,
  ),
  pure,
);

export const Component = enhance(NewQuoteComponent);

export default enhance(NewQuoteComponent);

import * as R from 'ramda';
import { eventChannel } from 'redux-saga';
import { all, put, call, take, fork, race, delay, select } from 'redux-saga/effects';
// features
import { makeSelectItemList } from '../../../available-driver/selectors';
import { getItemListSuccess as getAvailableDriversListSuccess } from '../../../available-driver/actions';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// utilities
import endpointsMap from '../../../../utilities/endpoints';
// feature sockets
import * as A from '../../actions';
import * as LC from '../../constants';
import { clientTopics, openStompClient, getConnectedPromise, unsubscribeFromDrivers } from '../../helpers';
//////////////////////////////////////////////////

function* watchEventChannelMessagesSaga(eventChannel: Object) {
  while (true) { // eslint-disable-line
    const event = yield take(eventChannel);

    const { type, payload } = event;

    const socketDataType = R.path(['data', LC.SOCKET_DATA_TYPE], payload);

    if (R.equals(type, LC.SOCKET_CHANNEL_AVAILABLE_DRIVERS_RECEIVED)) {
      if (R.equals(socketDataType, LC.SOCKET_DATA_TYPE_AVAILABLE_DRIVERS_RESERVATION)) {
        yield put(A.socketAvailableDriversReservationReceived(payload));
      }

      if (R.equals(socketDataType, LC.SOCKET_DATA_TYPE_AVAILABLE_DRIVERS_STATUS)) {
        yield put(A.socketAvailableDriversStatusReceived(payload));
      }

      if (R.equals(socketDataType, LC.SOCKET_DATA_TYPE_AVAILABLE_DRIVERS_NOTE)) {
        yield put(A.socketAvailableDriversNoteReceived(payload));
      }

      if (R.equals(socketDataType, LC.SOCKET_DATA_TYPE_AVAILABLE_DRIVERS_LAST_KNOWN_LOCATION)) {
        yield put(A.socketAvailableDriversLocationReceived(payload));
      }
    }
  }
}

const runStompClientAvailableDrivers = (
  client: Object,
  { accessToken, driverGuids }: Object,
) => eventChannel((emit: Function) => {
  driverGuids.forEach((driverGuid: Object) => {
    const topic = clientTopics.getAvailableDrivers(driverGuid);

    client.subscribe(topic, (message: Object) => {
      emit({
        type: LC.SOCKET_CHANNEL_AVAILABLE_DRIVERS_RECEIVED,
        payload: {
          driverGuid,
          data: JSON.parse(message.body),
          type: LC.SOCKET_CHANNEL_AVAILABLE_DRIVERS_RECEIVED,
        },
      });
    }, { id: driverGuid, 'access-token': accessToken });
  });

  return () => unsubscribeFromDrivers(driverGuids, accessToken, client);
});

// TODO: check if every time created new eventChannel
function* runStompClientAvailableDriversOnChangeListSaga(client: Object, { payload }: Object, data: Object) {
  const { driverList, prevDriverListGuids } = payload;

  const { accessToken } = data;

  const driverListGuids = R.map(R.prop(GC.FIELD_GUID), driverList);
  const driversToSubscribe = R.difference(driverListGuids, prevDriverListGuids);
  const driversToUnsubscribe = R.difference(prevDriverListGuids, driverListGuids);

  unsubscribeFromDrivers(driversToUnsubscribe, accessToken, client);

  const eventChannel = yield call(
    runStompClientAvailableDrivers,
    client,
    R.assoc(GC.FIELD_DRIVER_GUIDS, driversToSubscribe, data),
  );

  yield call(watchEventChannelMessagesSaga, eventChannel);
}

function* watchGetAvailableDriversListSuccessSaga(client: Object, data: Object) {
  while (true) { // eslint-disable-line
    const action = yield take(getAvailableDriversListSuccess);

    yield fork(runStompClientAvailableDriversOnChangeListSaga, client, action, data);
  }
}

function* checkAvailableDriversSocketErrorOrClose(client: Object) {
  const onDisconnect = new Promise((res: Function) => {
    client.onWebSocketError = (payload: Object) => res(payload); // eslint-disable-line
    client.onWebSocketClose = (payload: Object) => res(payload); // eslint-disable-line
  });

  yield onDisconnect;

  yield put(A.socketAvailableDriversDisconnectRequest());

  yield delay(5000);

  const itemList = yield select(makeSelectItemList());

  if (G.isNotNilAndNotEmpty(itemList)) {
    yield put(A.socketAvailableDriversReconnectRequest(R.keys(itemList)));
  }
}

function* waitForSocketAvailableDriversConnected(client: Object) {
  yield getConnectedPromise(client);

  yield put(A.socketAvailableDriversConnected());
}

function* watchSocketAvailableDriversRequestSaga() {
  try {
    while (true) { // eslint-disable-line
      const { connectAction, reconnectAction } = yield race({
        connectAction: take(A.socketAvailableDriversConnectRequest),
        reconnectAction: take(A.socketAvailableDriversReconnectRequest),
      });

      const action = R.or(connectAction, reconnectAction);

      const data = {
        reconnectDelay: 0,
        endpoint: endpointsMap.fleetSocket,
        accessToken: G.getAuthTokenFromSession(),
        userGuid: G.getAmousCurrentUserGuidFromWindow(),
        driverGuids: G.getPropFromObject('payload', action),
        socketChannelType: LC.SOCKET_CHANNEL_AVAILABLE_DRIVERS_RECEIVED,
      };

      const client = yield call(openStompClient, data);

      yield fork(checkAvailableDriversSocketErrorOrClose, client);

      yield fork(waitForSocketAvailableDriversConnected, client);

      yield take(A.socketAvailableDriversConnected);

      const eventChannel = yield call(runStompClientAvailableDrivers, client, data);

      const { cancel } = yield race({
        cancel: take(A.socketAvailableDriversDisconnectRequest),
        task: all([
          fork(watchGetAvailableDriversListSuccessSaga, client, data),
          call(watchEventChannelMessagesSaga, eventChannel),
        ]),
      });

      if (cancel) eventChannel.close();
    }
  } catch (error) {
    console.log('///////////////////////////////////////', 'catch watchSocketAvailableDriversRequestSaga');
  }
}

function* availableDriversSocketWatcherSaga() {
  yield fork(watchSocketAvailableDriversRequestSaga);
}

export default availableDriversSocketWatcherSaga;
